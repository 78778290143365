import React, { useEffect, Fragment, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import './nav-mobile-menu.scss';
import navLinks from './nav-link'
import { FilePath, FormatNameToFilePath } from '../../../services/utilities';
import { MdHiking, MdDownhillSkiing, MdHouse, MdOutlineKayaking, MdMenu } from 'react-icons/md';
import { AdminUserContext } from '../../../contexts/user-context';
import { useOktaAuth } from '@okta/okta-react';
import intersection from 'lodash/intersection';
import NavIcon from './nav-icon';


const NavMobileMenu = () => {
    const { adminUserProfile, setAdminUserProfile, isImpersonated, setIsImpersonated } = useContext(AdminUserContext);
    const { oktaAuth, authState } = useOktaAuth();
    const logout = async () => oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/loggedout' });

    const stopImpersonate = () => {
        localStorage.removeItem("T1AdminImpersonate");
        setIsImpersonated(false);
        setAdminUserProfile();
    };

    return (
        <nav className="navbar bg-dark w-100">
            <div className="container-fluid">
                <div className="d-flex">
                    <a href="/" className="pe-2">
                        <img src={FilePath('/images/logos/TOnly.png')} alt="TitleOne Logo" className="bi" aria-label="TitleOne Logo" height="45px" />
                    </a>
                    {authState?.isAuthenticated && adminUserProfile &&
                        <div className="dropdown">
                            <a href="##" className="d-flex align-items-center text-white text-decoration-none display-4" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                <MdMenu />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownMenu">
                                <ul className="nav nav-pills flex-column mb-auto">
                                    {navLinks.filter(l => adminUserProfile.memberOf.includes("webdev") || intersection(l.userGroups, adminUserProfile.memberOf).length > 0).map((l, i) => {
                                        return <li key={i} className="nav-item">
                                            <Link to={l.route} className="dropdown-item"><NavIcon iconKey={l.iconKey} /><span className="ps-2">{l.title}</span></Link>
                                        </li>
                                    })}
                                </ul>
                            </ul>
                        </div>
                    }
                </div>
                {isImpersonated && <div className="text-danger text-center">IMPERSONATING</div>}
                {authState?.isAuthenticated && adminUserProfile &&
                    <div className="btn-group dropstart">
                        <span className="text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={FilePath(`/teamimages/${FormatNameToFilePath(`${adminUserProfile.firstName} ${adminUserProfile.lastName}`)}.png`)} alt="" width="32" height="32" className="rounded-circle me-2" />
                        </span>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                            {isImpersonated && <>
                                <li><span className="dropdown-item" role="button" onClick={stopImpersonate}>Stop Impersonating</span></li>
                                <li><hr className="dropdown-divider" /></li>
                                </>}
                            {!isImpersonated && adminUserProfile.memberOf.includes("webdev") && <li><Link className="dropdown-item" to="/impersonate">Impersonate</Link></li>}            
                            <li><span className="dropdown-item" role="button" onClick={logout}>Sign out</span></li>
                        </ul>
                    </div>
                }
            </div>
        </nav>
    );
}

export default NavMobileMenu;