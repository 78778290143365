import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdDelete, MdAdd } from 'react-icons/md';

const RepsOfTheYear = (props) => {
    const [repToDelete, setRepToDelete] = useState(null);
    const [saved, setSaved] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState([]);
    const [modifiedRows, setModifiedRows] = useState([]);
    const [idCounter, setIdCounter] = useState(0);

    const columns = [
        { field: 'name', headerName: 'Name', flex: 250, editable: true },
        { field: 'position', headerName: 'Position', flex: 250, editable: true },
        { field: 'region', headerName: 'Region', flex: 250, editable: true },
        { field: 'trackedValue', headerName: 'New Face Orders', flex: 250, editable: true },
        { field: 'lastUpdated', headerName: 'Last Updated', flex: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setRepToDelete(id); setDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal" role="button"
                    />,
                ];
            }
        }
    ];

    const getRepsOfTheYear = async () => {
        apiAdmin.get(`/reps-of-the-year`)
            .then((response) => {
                setRows(response.data.map((a) => ({
                    repId: a.repId,
                    name: a.name,
                    position: a.position,
                    trackedValue: a.trackedValue,
                    lastUpdated: a.lastUpdated ? new Date(a.lastUpdated).toLocaleDateString() : null,
                    region: a.region
                })));
            });
    }

    useEffect(() => {
        getRepsOfTheYear();
    }, []);

    const deleteRep = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-rep?repId=` + repToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setRepToDelete(null);
                        setDeleted(true);
                        getRepsOfTheYear();
                    } else {
                        setError("There was an error deleting the rep. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    const saveRepsOfTheYear = async (modifiedRows, setModifiedRows) => {
        if (modifiedRows.length !== 0) {
            const repObject = {
                ...modifiedRows,
            }
            await apiAdmin.post(`/save-rep-info`, repObject)
                .then((response) => {
                    if (response.data) {
                        setSaved(true);
                        setModifiedRows([]);
                        getRepsOfTheYear();
                    } else {
                        setError("There was an error saving the sales rep information. Please try again.")
                    }
                });
        } else {
            setError("No data to save. Nothing has been added or changed.");
        }
    }

    const processRowUpdate = (newRow, oldRow) => {
        setRows((prevRows) => {
            const updatedRows = prevRows.map((row) => (row.repId === newRow.repId ? newRow : row));
            return [newRow, ...updatedRows.filter((row) => row.repId !== newRow.repId)];
        });
        setModifiedRows((prevModifiedRows) => {
            const existingRow = prevModifiedRows.find((row) => row.repId === newRow.repId);
            if (existingRow) {
                return prevModifiedRows.map((row) => (row.repId === newRow.repId ? newRow : row));
            } else {
                return [newRow, ...prevModifiedRows];
            }
        });
        return newRow;
    };

    const addNewRecordInline = (setRows, setModifiedRows) => {
        setIdCounter(prevCounter => prevCounter - 1);
        const newId = idCounter - 1;
        const newRecord = { repId: newId, name: 'Add name', position: 'Add position', region: 'Add region', trackedValue: 0, lastUpdated: null };
        setRows((prevRows) => [newRecord, ...prevRows]);
        setModifiedRows((prevModifiedRows) => [newRecord, ...prevModifiedRows]);
    };
    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => { addNewRecordInline(setRows, setModifiedRows) }}>
                    <MdAdd className="mb-1" /> Add New Sales Rep
                </span>
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            <Helmet>
                <title>Reps of the Year</title>
            </Helmet>
            <h1>Reps of the Year - Dove Mountain Tracker</h1>

            {deleted && <div className="alert alert-warning alert-dismissible" role="alert">Rep deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
            {error && <div className="alert alert-danger alert-dismissible" role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>}

            <DataGridPro
                rows={rows}
                columns={columns}
                loading={rows.length === 0}
                slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                autoHeight
                rowHeight={38}
                disableRowSelectionOnClick
                alignItems="center"
                slots={{
                    toolbar: EditToolbar,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                pagination
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                getRowId={(row) => row.repId}
            />

            <button className="btn btn-primary m-2" onClick={() => saveRepsOfTheYear(modifiedRows, setModifiedRows)}>Save</button>
            {saved && (
                <div className="modal shown d-block" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p>Rep Information saved successfully!</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => setSaved(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete rep?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this sales rep?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteRep()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RepsOfTheYear;
