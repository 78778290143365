import React from 'react';
import { MdPersonRemove } from 'react-icons/md';
import { FilePath, FormatNameToFilePath } from '../../../services/utilities';

const EcardEditorTeamMember = ({ teamMembers, sender, updateSender, removeSender, senderCount }) => {

    return (<>
        {teamMembers && sender && <div>
            <div className="d-flex flex-row flex-wrap pb-3">
                <label htmlFor={`sender-${sender.senderId}`} className="form-label pe-1 pt-2">Sender:</label>
                <select id={`sender-${sender.senderId}`} name={`sender-${sender.senderId}`} className="form-select align-self-start w-auto" value={sender.teamMember.teamMemberId} onChange={(e) => { updateSender({ ...sender, teamMember: teamMembers.find(t => t.teamMemberId === parseInt(e.target.value)) }); }} >
                    {teamMembers.map((t, i) => { return <option key={i} value={t.teamMemberId}>{`${t.firstName} ${t.lastName}`}</option> })}
                </select>
                <button type="button" className="btn btn-link me-3 align-self-start" disabled={(senderCount === 1)} onClick={() => { removeSender(sender); }}><MdPersonRemove title="Remove Sender" className="md-react-icons" /></button>
                <div className="form-check pt-2 pe-3">
                    <input className="form-check-input" type="checkbox" id={`senderCopied-${sender.senderId}`} checked={sender.senderCopied} onChange={(e) => { updateSender({ ...sender, senderCopied: e.target.checked }); }} />
                    <label className="form-check-label pt-1" htmlFor={`senderCopied-${sender.senderId}`}>Receive a copy</label>
                </div>
                <div className="form-check pt-2 pe-3">
                    <input className="form-check-input" type="checkbox" id={`senderPhotoIncluded-${sender.senderId}`} checked={sender.senderPhotoIncluded} onChange={(e) => { updateSender({ ...sender, senderPhotoIncluded: e.target.checked }); }} />
                    <label className="form-check-label pt-1" htmlFor={`senderPhotoIncluded-${sender.senderId}`}>Include Photo</label>
                </div>
                <div>
                    {sender.senderPhotoIncluded &&
                        <img src={FilePath(`/teamimages/${FormatNameToFilePath(`${sender.teamMember.firstName} ${sender.teamMember.lastName}`)}.png`)} className="m-auto d-block w-50" alt={`${sender.teamMember.firstName} ${sender.teamMember.lastName}`} />
                    }
                </div>
            </div>
        </div>}
        </>
    );
}

export default EcardEditorTeamMember;
