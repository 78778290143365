import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter, GridOverlay } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { TextField, MenuItem, Modal, Box, Button } from '@mui/material';
import './golf-tournament.scss';

const GolfSponsors = ({ golfTournamentIdToEdit, golfTournamentToEdit }) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }, control} = useForm();
    const [error, setError] = useState(null);
    const [golfSponsors, setGolfSponsors] = useState([]);
    const [golfSponsorIdToEdit, setGolfSponsorIdToEdit] = useState(null);
    const [sponsorDeleted, setSponsorDeleted] = useState(false);
    const [golfSponsorIdToDelete, setGolfSponsorIdToDelete] = useState(null);
    const [sponsorSubmitted, setSponsorSubmitted] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState('');
    const [level, setLevel] = useState([]);
    const [sponsor, setSponsor] = useState([]);
    const [selectedSponsor, setSelectedSponsor] = useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const golfSponsorColumns = [
        { field: 'name', headerName: 'Name', flex: 150 },
        { field: 'sponsorLevel', headerName: 'Sponsor Level', flex: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            setGolfSponsorIdToEdit(id);
                            setOpenAddModal(true); 
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => {
                            setGolfSponsorIdToDelete(id);
                            setSponsorDeleted(false);
                            setOpenDeleteModal(true); 
                        }}
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getTourneySponsors = async () => {
            apiAdmin.get(`/get-sponsors?tournamentId=${golfTournamentIdToEdit}`)
                .then((response) => {
                    setGolfSponsors(response.data.map((a) => ({ id: a.tournamentSponsorId, name: a.golfSponsor.name, sponsorLevel: a.golfSponsorLevel.name })));
                });
        }
        getTourneySponsors();

        const getSponsorNames = async () => {
            apiAdmin.get(`/get-sponsor-names`)
                .then((response) => {
                    const sponsorsWithNewOption = [
                        { sponsorId: -1, name: 'New Sponsor' },
                        ...response.data
                    ];
                    setSponsor(sponsorsWithNewOption);
                });
        }
        getSponsorNames();

        const fetchLevels = async () => {
            const response = await apiAdmin.get(`/get-levels?regionId=${golfTournamentToEdit.regionId}`)
            setLevel(response.data);
        };

        fetchLevels();

    }, [sponsorSubmitted, sponsorDeleted, golfTournamentIdToEdit]);

    useEffect(() => {
        if (!golfSponsorIdToEdit || golfSponsorIdToEdit === -1) return;
        const getSponsor = async () => {
            const response = await apiAdmin.get(`/get-sponsor?tournamentSponsorId=${golfSponsorIdToEdit}`);
            setValue('name', response.data.golfSponsor.name);
            setSelectedLevel(response.data.golfSponsorLevel.sponsorLevelId);
            setValue('level', response.data.golfSponsorLevel.sponsorLevelId);
            setSelectedSponsor(response.data.golfSponsor.sponsorId);
            setValue('sponsor', response.data.golfSponsor.sponsorId);
        };

        getSponsor();
    }, [golfSponsorIdToEdit, setValue]);
    function SponsorToolbar(props) {
        return (
            <div>
                <GridToolbarContainer className="d-flex align-items-end justify-content-between">
                    <Button
                        className="data-grid-link"
                        variant="text"
                        startIcon={<MdAdd />}
                        onClick={() => setOpenAddModal(true)}
                    >
                        Add Sponsor
                    </Button>
                    <GridToolbarQuickFilter />
                </GridToolbarContainer>
            </div>
        );
    }
    function CustomNoRowsOverlay(props) {
        return (
            <GridOverlay>
               No sponsors available.
            </GridOverlay>
        );
    }

    const saveSponsor = data => {
        const sponsorObject = {
            ...data,
            tournamentSponsorId: golfSponsorIdToEdit,
            tournamentId: golfTournamentIdToEdit,
            sponsorId: selectedSponsor,
            sponsorLevelId: selectedLevel,
            name: data.newSponsorName,
        }

        const saveSponsorInfo = async () => {
            await apiAdmin.post(`/save-sponsor`, sponsorObject)
                .then((response) => {
                    if (response.data) {
                        setGolfSponsorIdToEdit(null);
                        setSponsorSubmitted(true);
                        reset();
                        setSelectedLevel('');
                        setSelectedSponsor('');
                        setOpenAddModal(false);
                    } else {
                        setError("There was an error saving the sponsor. Please try again.")
                    }
                });
        }
        saveSponsorInfo();
    }

    const deleteGolfSponsor = () => {
        const deleteSponsor = async () => {
            await apiAdmin.post(`/delete-golf-sponsor?tournamentSponsorId=` + golfSponsorIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setGolfSponsorIdToDelete(null);
                        setSponsorDeleted(true);
                        setOpenDeleteModal(false);
                    } else {
                        setError("There was an error deleting the sponsor. Please try again.")
                    }
                });
        }
        deleteSponsor();
    }
   
    return (
        <div>
            <h2 className="mt-3">Golf Sponsors</h2>
            {sponsorDeleted && <div className="alert alert-warning alert-dismissible" role="alert">Sponsor deleted successfully!<button type="button" className="btn-close" onClick={() => setSponsorDeleted(false)} aria-label="Close"></button></div>}
            {sponsorSubmitted && <div className="alert alert-success alert-dismissible" role="alert"> Sponsor saved successfully!<button type="button" className="btn-close" onClick={() => setSponsorSubmitted(false)} aria-label="Close"></button></div>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <DataGridPro
                rows={golfSponsors}
                columns={golfSponsorColumns}
                autoHeight
                rowHeight={38}
                disableRowSelectionOnClick
                alignItems="center"
                slots={{
                    toolbar: SponsorToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                pagination
            />
            <Modal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                disableEnforceFocus
            >
                <Box className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Sponsor?</h5>
                            <Button type="button" className="btn-close" onClick={() => setOpenDeleteModal(false)} aria-label="Close"></Button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this sponsor?</p>
                        </div>
                        <div className="modal-footer">
                            <Button type="button" className="cancel-button" onClick={() => setOpenDeleteModal(false)}>No, Cancel</Button>
                            <Button type="button" className="submit-button" onClick={() => deleteGolfSponsor()}>Yes, Delete</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openAddModal}
                onClose={() => setOpenAddModal(false)}
                disableEnforceFocus
            >
                <Box className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addSponsorModalLabel">Update Sponsor</h5>
                            <Button type="button" className="btn-close" onClick={() => setOpenAddModal(false)} aria-label="Close"></Button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(saveSponsor)}>
                                <div className="row m-3">
                                    <div className="col mb-3">
                                        <Controller
                                            name="sponsor"
                                            control={control}
                                            defaultValue={selectedSponsor || ''}
                                            rules={{ required: 'Sponsor is required' }}
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        select
                                                        label="Sponsor"
                                                        value={selectedSponsor || ''}
                                                        className="w-100"
                                                        error={!!errors.sponsor}
                                                        helperText={errors.sponsor ? errors.sponsor.message : ''}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setSelectedSponsor(e.target.value);
                                                        }}
                                                    >
                                                        {sponsor.map((option) => (
                                                            <MenuItem key={option.sponsorId} value={option.sponsorId}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    {selectedSponsor && selectedSponsor === -1 && (
                                                        <div className="mt-3">
                                                            <TextField
                                                                label="New Sponsor Name"
                                                                {...register("newSponsorName", { required: 'New Sponsor Name is required' })}
                                                                className="w-100"
                                                                error={!!errors.newSponsorName}
                                                                helperText={errors.newSponsorName ? errors.newSponsorName.message : ''}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Controller
                                            name="level"
                                            control={control}
                                            defaultValue={selectedLevel}
                                            rules={{ required: 'Level is required' }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Level"
                                                    value={selectedLevel || ''}
                                                    className="w-100"
                                                    error={!!errors.level}
                                                    helperText={errors.level ? errors.level.message : ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedLevel(e.target.value);
                                                    }}
                                                >
                                                    {level.map((option) => (
                                                        <MenuItem key={option.sponsorLevelId} value={option.sponsorLevelId}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                                <Button type="button" className="submit-button" onClick={handleSubmit(saveSponsor)}>Submit</Button>
                                <Button type="button" className="cancel-button" onClick={() => { setSponsorSubmitted(null); setError(null); reset(); setSelectedLevel(null); setSelectedSponsor(null); setOpenAddModal(false); }}>Cancel</Button>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
       </div>
    );
}

export default GolfSponsors;
