import { createContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import apiAdmin from '../services/api-admin';

const AdminUserContext = createContext();

const AdminUserProvider = ({ children }) => {
    const [adminUserProfile, setAdminUserProfile] = useState();
    const [isImpersonated, setIsImpersonated] = useState(false);
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        const getUserProfile = async () => { 
            apiAdmin.get(`/auth/profile`)
                .then((response) => {
                    setAdminUserProfile(response.data);
                });
        };
        if (!oktaAuth || !authState?.isAuthenticated || adminUserProfile) return; 
        localStorage.removeItem("T1AdminImpersonate");
        getUserProfile();
    }, [oktaAuth, authState, adminUserProfile]);

    return (
        <AdminUserContext.Provider value={{
            adminUserProfile: adminUserProfile, setAdminUserProfile: setAdminUserProfile, isImpersonated: isImpersonated, setIsImpersonated: setIsImpersonated
        }}>
            {children}
        </AdminUserContext.Provider>
    )
}

export { AdminUserContext, AdminUserProvider }