/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { TextField, } from '@mui/material';
import { FilePath, FormatMoney } from '../../services/utilities';

const PromoItemRow = ({ promoItem, addToCart, setPreviewItem, settings, cart, promoOrderItems }) => {
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState('');

    useEffect(() => {
        if (cart && cart.length === 0) {
            setQuantity('');
            setTotal(0);
        }
    }, [cart]);   

    useEffect(() => {
        if (!promoOrderItems) return;
        const promoOrderItem = promoOrderItems.find(item => item.promoItemId === promoItem.promoItemId);
        if (promoOrderItem) {
            setQuantity(promoOrderItem.quantity);
            setTotal(promoOrderItem.quantity * (Math.round(promoItem.price * settings.shippingAndHandlingRate * 100) / 100));
        }
    }, [promoOrderItems]);

    return (<>
        <div className="row border-top py-1" style={{height:'100px'}}>
                <div className="col-2">
                <TextField type="number" step="1" helperText={"min " + promoItem.minimumOrderQuantity} value={quantity} onChange={(e) => { setTotal(e.target.value * (Math.round(promoItem.price * settings.shippingAndHandlingRate * 100) / 100)); setQuantity(e.target.value); addToCart(promoItem, e.target.value) } }></TextField>
                </div>
                <div className="col-4">{promoItem.itemName} {promoItem.new && <strong>New!</strong>}</div>
                <div className="col-2 h-100"><img src={FilePath('/promoitemimages/' + promoItem.photo)} alt={promoItem.itemName} className="w-auto h-auto mw-100 mh-100" data-bs-toggle="modal" data-bs-target="#previewModal" role="button" onClick={() => setPreviewItem(promoItem)} /></div>
                <div className="col-2">{FormatMoney(promoItem.price * settings.shippingAndHandlingRate)}</div>
                <div className="col-2">{FormatMoney(total)}</div>
            </div>
        </>
    );
}

export default PromoItemRow;
