/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import {  MdDelete, MdAdd} from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import {DateTime} from 'luxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const ForeclosureSaleActivities = ({ setForeclosureSaleActivityIdToDelete, foreclosureSaleId, activityDeleted, setActivityDeleted}) => {
    const { register, handleSubmit, getValues, reset, control, formState: { errors }  } = useForm();
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [addingForeclosureSaleActivity, setAddingForeclosureSaleActivity] = useState(false);
    const [activityType, setActivityType] = useState('Postpone');
    const [activities, setActivities] = useState([]);
    const [loadingActivities, setLoadingActivities] = useState(false);

    const columns = [
        { field: 'changeDate', headerName: 'Change Date', flex: 250, renderCell: function (t) { return t.value.toLocaleString("en-US")}  },
        { field: 'note', headerName: 'Note', flex: 500 },
        { field: 'saleDate', headerName: 'Previous Scheduled Sale Date', flex: 250, renderCell: function (t) { if (!t.value) return <></>; return t.value.toLocaleString("en-US").replace(':00 ', ' ')} },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 75,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                if (!parseInt(id)) return [];
                return [                                            
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setForeclosureSaleActivityIdToDelete(id); setActivityDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteActivityModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getActivities = async () => {
            setLoadingActivities(true);
            apiAdmin.get(`/foreclosure-sale-activities?foreclosureSaleId=`+ foreclosureSaleId)
                .then((response) => {
                    setActivities(response.data.map(r => ({
                        id: r.foreclosureSaleActivityId,
                        changeDate: new Date(r.changeDate),
                        note: r.note,
                        saleDate: r.saleDate ? new Date(r.saleDate) : null,
                    })));
                    setLoadingActivities(false);
                });
        }

        getActivities();

    }, [submitted, foreclosureSaleId, activityDeleted]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setAddingForeclosureSaleActivity(-1)} }>
                    <MdAdd className="mb-1"/> Add New Activity
                </span>
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {

        const foreclosureSaleActivityObject = {
            ...data,
            activityType: activityType,
            foreclosureSaleId: foreclosureSaleId
        }
        const addActivity = async () => {
            await apiAdmin.post(`/save-foreclosure-sale-activity`, foreclosureSaleActivityObject)
                .then((response) => {
                    if (response.data) {
                        setAddingForeclosureSaleActivity(false);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the activity. Please try again.")
                    }
                });
        }
        addActivity();
    }
    
    return (
        <div>
          
            {addingForeclosureSaleActivity ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <h3>Add Activity</h3>
                        <div className="mb-3">
                            <ToggleButtonGroup
                                value={activityType}
                                exclusive
                                onChange={(e, newType) => {setActivityType(newType)}}
                                aria-label="Activity Type"
                                >
                                    <ToggleButton value="Postpone" aria-label="Postpone">Postpone</ToggleButton>
                                    <ToggleButton value="Complete" aria-label="Complete">Sold/Complete</ToggleButton>
                                    <ToggleButton value="Cancel" aria-label="Cancel">Cancel</ToggleButton>

                            </ToggleButtonGroup>
                        </div>
                        {activityType === 'Postpone' && <div className="mb-3">
                            <Controller
                                name="saleDate"
                                control={control}
                                rules={{ validate: { dateValid: () => DateTime.fromISO(getValues("saleDate")).isValid } }}
                                render={({ field: { onChange, value } }) => (
                                    <DateTimePicker value={value} onChange={onChange} className="w-100" label="New Sale Date *" />
                                )}
                            />
                            {errors?.saleDate?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                        </div>}
                        {activityType === 'Complete' && <div className="mb-3">
                            <TextField
                                label="Final Sale Amount"
                                {...register("finalSaleAmount")}
                                className="w-100"
                                type="number"
                            />
                        </div>}
                        <div className="mb-3">
                            <TextField
                                label="Note"
                                {...register("note")}
                                className="w-100"
                            />
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary me-4">Submit</button>
                            <button type="button" className="btn btn-primary" onClick={() => { setAddingForeclosureSaleActivity(false); setSubmitted(false); setActivityDeleted(false); setError(null); reset(); } }>Cancel</button>
                        </div>
                    </div>

                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Activity saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {activityDeleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Activity deleted successfully!<button type="button" className="btn-close" onClick={() => setActivityDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}

                    {<DataGridPro
                        rows={activities}
                        columns={columns}
                        loading={loadingActivities}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }} 
                        autoHeight
                        rowHeight={38}
                        disableRowSelectionOnClick
                        alignItems="center"
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            }
                        }}
                    />}
            </>
            }


        </div>
    );
}

export default ForeclosureSaleActivities;
