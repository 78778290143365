/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, useGridApiRef} from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, } from "react-hook-form";
import { TextField } from '@mui/material';

const HomePageCards = (props) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }  } = useForm();
    const [homePageCards, setHomePageCards] = useState([]);
    const [homePageCardIdToEdit, setHomePageCardIdToEdit] = useState(null);
    const [homePageCardToEdit, setHomePageCardToEdit] = useState(null);
    const [homePageCardIdToDelete, setHomePageCardIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const apiRef = useGridApiRef();

    const columns = [
        { field: 'title', headerName: 'Title', flex: 250 },
        { field: 'cardText', headerName: 'Text', flex: 550 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setHomePageCardIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setHomePageCardIdToDelete(id); setDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getBrands = async () => {
            apiAdmin.get(`/brands`)
                .then((response) => {
                    setBrands(response.data.filter(b => b.brandId !== 4));
                    setSelectedBrand(response.data[0]);
                });
        }
        getBrands();
    }, [submitted, deleted]);

    useEffect(() => {
        if (!selectedBrand) return;
        const getHomePageCards = async () => {
            apiAdmin.get(`/home-page-cards?selectedBrandId=${selectedBrand.brandId}`)
                .then((response) => {
                    setHomePageCards(response.data.map((a) => ({ id: a.homePageCardId, title: a.title, cardText: a.cardText })));

                });
        }

        getHomePageCards();
    }, [selectedBrand]);



    useEffect(() => {
        if (!homePageCardIdToEdit || homePageCardIdToEdit === -1) return;
        const getHomePageCard = async () => {
            apiAdmin.get(`/home-page-card?homePageCardId=${homePageCardIdToEdit}`)
                .then((response) => {
                    setHomePageCardToEdit(response.data);
                    Object.keys(response.data).forEach(function (key, index) {
                        setValue(key, response.data[key]);
                    });

                });
        }
        getHomePageCard();
    }, [homePageCardIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setHomePageCardIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Home Page Card
                </span>
                {selectedBrand &&
                    <div className="col">
                        <div className="dropdown float-end">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedBrand.brandName}
                            </button>
                            <ul className="dropdown-menu">
                                {brands && brands.map((brand, index) => {
                                    return <li key={"brand_" + brand.brandId}><span className="dropdown-item btn-link" onClick={() => setSelectedBrand(brand)}>{brand.brandName}</span></li>
                                })}
                            </ul>
                        </div>

                    </div>
                }
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        const homePageCardObject = {
            ...data,
            homePageCardId: homePageCardIdToEdit,
            brandId: selectedBrand.brandId
        }
        const saveHomePageCard = async () => {
            await apiAdmin.post(`/save-home-page-card`, homePageCardObject)
                .then((response) => {
                    if (response.data) {
                        setHomePageCardIdToEdit(null);
                        setHomePageCardToEdit(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the home page card. Please try again.")
                    }
                });
        }
        saveHomePageCard();
    }

    const deleteHomePageCard = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-home-page-card?homePageCardId=`+ homePageCardIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setHomePageCardIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the home page card. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    const handleRowOrderChange = (e) => {
        var obj = {
            oldIndex: e.oldIndex + 1,
            targetIndex: e.targetIndex + 1,
            homePageCardId: e.row.id,
            brandId: selectedBrand.brandId
        }
        const resortHomePageCards = async () => {
            await apiAdmin.post(`save-home-page-card-sort`, obj)
                .then((response) => {
                    if (response.statusText !== "OK") {
                        setError("There was an error resorting the home page cards. Please try again.")
                    }
                });
        }
        resortHomePageCards();
    }
    
    return (
        <div>
            <Helmet>
                <title>Home Page Cards</title>
            </Helmet>
            <h1>Home Page Cards</h1>
            
            {homePageCardToEdit || homePageCardIdToEdit === -1 ? <>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <TextField
                                label="Title *"
                                {...register("title", {required: true})}
                                className="w-100"
                            />
                            {errors?.title?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Text *"
                                {...register("cardText", {required: true})}
                                className="w-100"
                            />
                            {errors?.cardText?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Button Text *"
                                {...register("linkText", {required: true})}
                                className="w-100"
                            />
                            {errors?.linkText?.type === "required" && <small className="text-danger">This field is required</small>}

                        </div>                        
                        <div className="mb-3">
                            <TextField
                                label="Link *"
                                {...register("link", {required: true})}
                                className="w-100"
                            />
                            {errors?.link?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setHomePageCardIdToEdit(null); setHomePageCardToEdit(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Home Page Card saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Home Page Card deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <p className="mb-4">
                    The top three rows will be used on the website home page in order from left to right. 
                    <br/>Drag and drop the rows to arrange the sort order and automatically update the status.
                    <div className="float-md-end"><div className="btn border">Active</div><div className="btn ms-2 bg-secondary text-white">Inactive</div></div>
                </p>
                
                <DataGridPro
                    rows={homePageCards}
                    columns={columns}
                    loading={homePageCards.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    apiRef={apiRef}
                    getRowClassName={(params) => apiRef.current.getSortedRowIds().indexOf(params.row.id) > 2 ? 'bg-secondary text-white' : ''}
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Home Page Card?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this home page card?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteHomePageCard()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageCards;
