/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdAdd } from 'react-icons/md';
import { Paper, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import './preferred-customers.scss';
import useWindowDimensions from '../../services/useWindowDimensions';

const PreferredCustomerPayments = ({ preferredCustomerId, setPreferredCustomerIdToEdit }) => {
    const [preferredCustomerPayments, setPreferredCustomerPayments] = useState([]);
    const [addNonPaidSubscription, setAddNonPaidSubscription] = useState(false);
    const [features, setFeatures] = useState([]);
    const [featuresPaidFor, setFeaturesPaidFor] = useState([]);
    const [featuresToAdd, setFeaturesToAdd] = useState([]);
    const [added, setAdded] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { loadedSizeIsMobile } = useWindowDimensions();

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const paymentColumns = [
        { field: 'datePaid', headerName: 'Date Paid', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ') } },
        { field: 'expirationDate', headerName: 'Expiration Date', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ') } },
        { field: 'transactionId', headerName: 'Transaction ID', flex: 150 },
        { field: 'totalPaid', headerName: 'Amount Paid', flex: 250, renderCell: function (t) { return currencyFormatter.format(t.value) } }
    ];

    useEffect(() => {

        const getFeatures = async () => {
            apiAdmin.get(`/for-fee-site-features`)
                .then((response) => {
                    setFeatures(response.data);                    
                });
        }

        getFeatures();

        const getPreferredCustomer = async () => {
            apiAdmin.get(`/preferred-customer?preferredCustomerId=${preferredCustomerId}`)
                .then((response) => {
                    setPreferredCustomerPayments(response.data.preferredCustomerPayments);
                    setLoading(false);
                });
        }
        getPreferredCustomer();

        const getFeaturesPaidFor = async () => {
            apiAdmin.get(`/paid-features?preferredCustomerId=` + preferredCustomerId)
                .then((response) => {
                    setFeaturesPaidFor(response.data);                    
                });
        }

        getFeaturesPaidFor();
    }, [preferredCustomerId, added]);

    const addNonPaidSubscriptions = () => {
        if (featuresToAdd.length === 0) {
            setAddNonPaidSubscription(false);
            return;
        }
        const add = async () => {
            await apiAdmin.post(`/add-non-paid-subscriptions?preferredCustomerId=` + preferredCustomerId, featuresToAdd)
                .then((response) => {
                    if (response.data) {
                        setAdded(true);
                        setFeaturesToAdd([])
                        setAddNonPaidSubscription(false);
                    } else { 
                        setError("There was an error adding the non paid subscription. Please try again.")
                    }
                });
        }
        add();
    }

    function PaymentToolbar(props) {
        return (
            <GridToolbarContainer className=" py-2">
                <span className="btn-link btn" onClick={() => { setAddNonPaidSubscription(true); }}>
                    <MdAdd className="mb-1" /> Add Non Paid Subscription
                </span>
            </GridToolbarContainer>
        );
    }

    function DetailPanelContent({ row: rowProp }) {
        const [paymentDetails, setPaymentDetails] = useState([]);

        useEffect(() => {
            let isMounted = true;
            (async () => {
                const result = await apiAdmin.get(`/get-payment-details?paymentId=` + rowProp.id)
                    .then((response) => {
                        return response.data;
                    });
    
                if (!isMounted) {
                    return;
                }

                setPaymentDetails(result);
            })();

            return () => {
                isMounted = false;
            };
        }, [rowProp.id]);

        return <Paper className="p-3">
            <ul>
                {paymentDetails && paymentDetails.map((f, index) => <li key={"f_" + rowProp.id + "_" + index}>{f.title} - {currencyFormatter.format(f.amountPaid)}</li>)}
            </ul>
        </Paper>;
    }
    const getDetailPanelContent = (params) => <DetailPanelContent row={params.row} />;

    return (
        <div>
            {addNonPaidSubscription ? 
                <>
                <strong>Add Non Paid Subscription</strong>
                <FormGroup>
                        {features && features.map((f, index) => {
                            if (featuresPaidFor.map(p => p.forFeeSiteFeatureId).includes(f.forFeeSiteFeatureId)) { 
                                return <FormControlLabel key={"chk_" + index} control={<Checkbox defaultChecked disabled />} label={f.title} />
                            }
                            return <FormControlLabel key={"chk_" + index} control={<Checkbox checked={featuresToAdd.includes(f)} onChange={() => 
                                { 
                                    if (featuresToAdd.includes(f)) {
                                        setFeaturesToAdd(featuresToAdd.filter(c => c.forFeeSiteFeatureId !== f.forFeeSiteFeatureId)) 
                                    } else { 
                                        setFeaturesToAdd([...featuresToAdd, f]) 
                                    } } } />} label={f.title} />
                        })}
                </FormGroup>
                {featuresPaidFor.length > 0 ? <p>Added features will expire on {new Date(featuresPaidFor[0].subscriptionExpDate).toLocaleDateString()} to match previously purchased un-expired subscriptions.</p> : <p>Non paid subscriptions will expire in one year.</p>}
                <input type="button" value="Cancel" onClick={() => setAddNonPaidSubscription(false)} className="btn btn-primary me-2"/>
                <input type="button" value="Save" onClick={() => addNonPaidSubscriptions()} className="btn btn-primary"/>
                </> : <>
                    {added && <><div className="alert alert-success alert-dismissible" role="alert">Non paid subscription added successfully!<button type="button" className="btn-close" onClick={() => setAdded(false)} aria-label="Close"></button></div></>}
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    <DataGridPro
                        rows={preferredCustomerPayments.map(p => ({ id: p.preferredCustomerPaymentId, datePaid: new Date(p.datePaid), expirationDate: new Date(p.subscriptionExpDate), totalPaid: p.totalPaid, transactionId: p.transactionId }))}
                        columns={paymentColumns}
                        loading={loading}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            }
                        }}
                        slots={{
                            toolbar: PaymentToolbar,
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    expirationDate: !loadedSizeIsMobile,
                                    transactionId: !loadedSizeIsMobile,
                                }
                            },
                        }}
                        autoHeight
                        rowHeight={38}
                        disableRowSelectionOnClick
                        alignItems="center"
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={() => 'auto'}
                    />
                    <input type="button" className="btn btn-primary mt-3" value="Back To List" onClick={() => setPreferredCustomerIdToEdit(null)} />
                </>
            }
        </div>
    );
}

export default PreferredCustomerPayments;
