/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter, GridRowModes  } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEmail, MdLink, MdAdd } from 'react-icons/md';

const PromoOrderZones = (props) => {
    const [promoOrderZones, setPromoOrderZones] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [offices, setOffices] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [emailSent, setEmailSent] = useState(null);
    const [promoOrderZoneIdToEmail, setPromoOrderZoneIdToEmail] = useState(null);

    const columns = [
        { field: 'zoneName', headerName: 'Zone Name', flex: 450, editable: true },
        { field: 'office', 
            headerName: 'Office', 
            flex: 300, 
            editable: true,
            type: 'singleSelect',
            valueOptions: offices,
            getOptionValue: (value) => value.officeId,
            getOptionLabel: (value) => value.officeName,
        },
        { field: 'contact', 
            headerName: 'Contact', 
            flex: 300, 
            editable: true,
            type: 'singleSelect',
            valueOptions: teamMembers,
            getOptionValue: (value) => value.teamMemberId,
            getOptionLabel: (value) => value.firstName + ' ' + value.lastName,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEmail />}
                        label="Email"
                        onClick={() => { setPromoOrderZoneIdToEmail(id); setEmailSent(false); }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#emailModal" role="button" 
                    />,
                    <GridActionsCellItem
                        icon={<MdLink />}
                        label="Direct Link"
                        onClick={() => { window.location.href = row.directLink; }}
                        color="inherit"
                    />
                ];
            }
        }
    ];

     useEffect(() => {
         if (offices.length === 0 || teamMembers.length === 0) return;
        const getPromoOrderZones = async () => {
            apiAdmin.get(`/promo-order-zones`)
                .then((response) => {
                    setPromoOrderZones(response.data.map((a) => { 
                        return ({ id: a.promoOrderZoneId, zoneName: a.zoneName, contact: a.contactTeamMemberId, office: a.deliveryOfficeId, directLink: process.env.REACT_APP_CLIENT_URL + '/promo-order-form/' + a.promoOrderZoneId })
                    }));

                });
        }
        getPromoOrderZones();

    }, [teamMembers, offices]);

    useEffect(() => {
        const getTeamMembers = async () => {
            apiAdmin.get(`/team-members`)
                .then((response) => {
                    setTeamMembers(response.data.filter(b => b.brandId !== 4));
                });
        }
        getTeamMembers();

        const getOffices = async () => {
            apiAdmin.get(`/offices`)
                .then((response) => {
                    setOffices(response.data);
                });
        }
        getOffices();
    }, []);

    function EditToolbar(props) {

        const handleClick = () => {
            setPromoOrderZones((oldRows) => [{ id: -1, zoneName: '', office: -1, contact: -1, isNew: true }, ...oldRows]);
            setRowModesModel((oldModel) => ({
            ...oldModel,
            [-1]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
        };

        return (
            <GridToolbarContainer>
            <div className="row w-100">
                <div className="col">
                    <span className="btn-link btn" onClick={handleClick}>
                        <MdAdd className="mb-1"/> Add Promo Order Zone
                    </span>
                </div>
                <div className="col">
                    <GridToolbarQuickFilter className="w-100"/>
                </div>
                <div className="col">
                    <button type="button" className="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#emailModal" onClick={()=> setPromoOrderZoneIdToEmail(null)}>Send Reminders</button>
                </div>

            </div>

                
            </GridToolbarContainer>
        );
    }

    const processRowUpdate = (newRow) => {
        const promoOrderZoneObject = {
            promoOrderZoneId: newRow.id,
            contactTeamMemberId: newRow.contact,
            deliveryOfficeId: newRow.office,
            zoneName: newRow.zoneName
        }
        const savePromoOrderZone = async () => {
            await apiAdmin.post(`/save-promo-order-zone`, promoOrderZoneObject)
                .then((response) => {
                    if (response.data) {
                        setSubmitted(true);
                    } else { 
                        setError("There was an error saving the promo order zone. Please try again.")
                    }
                });
        }
        savePromoOrderZone();
        return { ...newRow, isNew: false };

  };

    const sendPromoEmail = () => {
        const send = async () => {
            await apiAdmin.post(`/send-promo-reminder-email?promoOrderZoneId=`+ promoOrderZoneIdToEmail)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setEmailSent(true);
                        setPromoOrderZoneIdToEmail(null);
                    } else { 
                        setError("There was an error emailing the promo order reminder. Please try again.")
                    }
                });
        }
        send();
    }
    
    return (
        <div>
            <Helmet>
                <title>Promo Order Zones</title>
            </Helmet>
            <h1>Promo Order Zones</h1>
            {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Promo Order Zone saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
            {emailSent && <><div className="alert alert-success alert-dismissible" role="alert">Email sent successfully!<button type="button" className="btn-close" onClick={() => setEmailSent(false)} aria-label="Close"></button></div></>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <p>Double click a row to start editing. Click anywhere outside of the table to save the zone.</p>
            <DataGridPro
                rows={promoOrderZones}
                columns={columns}
                loading={promoOrderZones.length === 0}
                slotProps={{
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    },
                }}
                autoHeight
                rowHeight={72}
                disableRowSelectionOnClick
                alignItems="center"
                slots={{
                    toolbar: EditToolbar,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                pagination
                onRowEditStart={() => { setSubmitted(false); setError(false); } }
                editMode="row"
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={()=> setError("There was an issue updating the promo order zone. Please try again.")}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newRowModesModel) => { setRowModesModel(newRowModesModel)}}

            />
            <div className="modal shown" tabIndex="-1" id="emailModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Email Promo Order Reminder?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to email out this reminder?</p>
                            {!promoOrderZoneIdToEmail && <p>This will send an email to all promo order zone contacts.</p>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setPromoOrderZoneIdToEmail(null)}>No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => sendPromoEmail()}>Yes, Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromoOrderZones;
