import React, { useContext } from 'react';
import "./home.scss";
import { Helmet } from 'react-helmet-async';
import { AdminUserContext } from '../../contexts/user-context';
import { MdMenu } from 'react-icons/md';
import { FilePath } from '../../services/utilities';

const Home = () => {
    const { adminUserProfile } = useContext(AdminUserContext);
    return (
        <>
            <Helmet>
                <title>TitleOne Admin</title>
                <meta name="description" content="TitleOne Admin Site" />
            </Helmet>
            <div className="content">
                <h1>Welcome, {adminUserProfile.firstName}!</h1>
                <h2 className="d-none d-md-block mt-5"> Get started here!</h2>
                <p className="d-none d-md-block">
                    <img src={FilePath('/images/admin/get-started-arrow.webp')} width="200" height="144" alt="Start here arrow" />
                </p>
                <h2 className="d-block d-md-none">
                    Click the hamburger menu <MdMenu /> to get started!
                </h2>
            </div>
            <div className="image-container">
                <img src={FilePath('/images/admin/admin-mountains.webp')} alt="TitleOne mountains" className="img-fluid" />
            </div>
        </>
    );
}

export default Home;
