/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { TextField, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTime} from 'luxon';
import GolfSponsors from './golf-sponsors';
import { useOktaAuth } from '@okta/okta-react';

const GolfTournaments = () => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control, trigger } = useForm();
    const { authState } = useOktaAuth();
    const [golfTournaments, setGolfTournaments] = useState([]);
    const [golfTournamentIdToEdit, setGolfTournamentIdToEdit] = useState(null);
    const [golfTournamentToEdit, setGolfTournamentToEdit] = useState(null);
    const [golfTournamentIdToDelete, setGolfTournamentIdToDelete] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [course, setCourse] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [region, setRegion] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    
    const columns = [
        { field: 'name', headerName: 'Name', flex: 750},
        { field: 'date', headerName: 'Date', flex: 150, renderCell: function (t) { return t.value.toLocaleDateString("en-US")} },
        { field: 'active', headerName: 'Active', flex: 200 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => { setGolfTournamentIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setGolfTournamentIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getGolfTournaments = async () => {
            apiAdmin.get(`/golf-tournaments`)
                .then((response) => {
                    setGolfTournaments(response.data.map((a) => ({ id: a.tournamentId, name: a.name, date: new Date(a.date), active: a.active ? "ACTIVE" : "NOT Active" })));
                });
        }

        getGolfTournaments();

        const getCourses = async () => {
            apiAdmin.get(`/get-courses`)
                .then((response) => {
                    setCourse(response.data);
                });
        }
        getCourses();

        const getRegions = async () => {
            apiAdmin.get(`/get-golf-regions`)
                .then((response) => {
                    setRegion(response.data);
                });
        }
        getRegions();

    }, [submitted, deleted]);

    useEffect(() => {
        if (!golfTournamentIdToEdit || golfTournamentIdToEdit === -1) return;
        const getTournament = async () => {
            apiAdmin.get(`/get-tournament?tournamentId=${golfTournamentIdToEdit}`)
                .then((response) => {
                    setGolfTournamentToEdit(response.data);
                    setValue('name', response.data.name);
                    setValue('date', DateTime.fromISO(response.data.date));
                    setSelectedCourse(response.data.golfCourseId);
                    setSelectedRegion(response.data.regionId);
                    setValue('active', response.data.active);
                    setValue('adminUserName', response.data.adminUserName);
                    setValue('adminPassword', response.data.adminPassword);
                    setValue('showScoreMarkings', response.data.showScoreMarkings);
                });
        }

        getTournament();

    }, [golfTournamentIdToEdit]);

    function TournamentToolbar(props) {
        return (
            <GridToolbarContainer className="d-flex align-items-end justify-content-between">
                <span className="btn-link btn" onClick={() => {setGolfTournamentIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Tournament
                </span>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        const tournamentObject = {
            ...data,
            tournamentId: golfTournamentIdToEdit,
            golfCourseId: selectedCourse,
            regionId: selectedRegion,
        }
        const saveGolfTournament = async () => {
            await apiAdmin.post(`/save-tournament`, tournamentObject)
                .then((response) => {
                    if (response.data) {
                        setGolfTournamentIdToEdit(null);
                        setGolfTournamentToEdit(null);
                        setSubmitted(true);
                        reset();
                        setSelectedCourse('');
                        setSelectedRegion('');
                    } else { 
                        setError("There was an error saving the tournament. Please try again.")
                    }
                });
        }
        saveGolfTournament();
    }

    const handleButtonClick = async () => {
        const result = await trigger();
        if (result) {
            handleSubmit(onSubmit)();
        }
    };

    const deleteGolfTournament = () => {
        const deleteTournament = async () => {
            await apiAdmin.post(`/delete-golf-tournament?tournamentId=` + golfTournamentIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setGolfTournamentIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the tournament. Please try again.")
                    }
                });
        }
        deleteTournament();
    }

    return (
        <div className="golf-tournaments">
            <Helmet>
                <title>Golf Tournaments</title>
            </Helmet>
            <h1>Golf Tournaments</h1>
            <p>Make sure Southwest (TV) Idaho and South Central (MV) Idaho have one active tournament.</p>
            
            {golfTournamentToEdit || golfTournamentIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <div className="form-check form-switch col-6">
                                <label className="form-check-label" htmlFor="active">Active</label>
                                <input className="form-check-input" type="checkbox" id="active" {...register("active")} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Name *"
                                {...register("name", {required: true})}
                                className="w-100"
                            />
                            {errors?.name?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="row">
                            <div className="col-6 mb-3">
                                <Controller
                                    name="course"
                                    control={control}
                                    defaultValue={selectedCourse || ''}
                                    rules={{ required: 'Course is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Course"
                                            value={selectedCourse || ''}
                                            className="w-100"
                                            error={!!errors.course}
                                            helperText={errors.course ? errors.course.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setSelectedCourse(e.target.value);
                                            }}
                                        >
                                            {course.map((option) => (
                                                <MenuItem key={option.golfCourseId} value={option.golfCourseId}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <Controller
                                    name="region"
                                    control={control}
                                    defaultValue={selectedRegion || ''}
                                    rules={{ required: 'Region is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Region"
                                            value={selectedRegion || ''}
                                            className="w-100"
                                            error={!!errors.region}
                                            helperText={errors.region ? errors.region.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setSelectedRegion(e.target.value);
                                            }}
                                        >
                                            {region.map((option) => (
                                                <MenuItem key={option.regionId} value={option.regionId}>
                                                    {option.regionName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="date"
                                control={control}
                                rules={{ validate: { dateValid: () => DateTime.fromISO(getValues("date")).isValid } }}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker value={value} onChange={onChange} className="w-100" label="Date *" />
                                )}
                            />
                            {errors?.date?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                        </div>
                    </div>
                </form>
                {golfTournamentToEdit && <div><GolfSponsors golfTournamentIdToEdit={golfTournamentIdToEdit} golfTournamentToEdit={golfTournamentToEdit} /></div>}
                <div className="p-2">
                    <button type="button" className="btn btn-primary me-4" onClick={handleButtonClick}>Save Tournament</button>
                    <button type="button" className="btn btn-primary" onClick={() => { setGolfTournamentIdToEdit(null); setGolfTournamentToEdit(null); setSubmitted(false); setError(null); reset(); setSelectedCourse(null); setSelectedRegion(null); } }>Cancel</button>
                </div>
            </>
            :
            <>
                {submitted && <div className="alert alert-success alert-dismissible" role="alert">Tournament saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
                {deleted && <div className="alert alert-warning alert-dismissible" role="alert"> Tournament deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)}aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={golfTournaments}
                    columns={columns}
                    loading={golfTournaments.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: TournamentToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    pagination
                    />
            </>
            }
            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Golf Tournament?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this golf tournament?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteGolfTournament()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GolfTournaments;
