/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter, GridRowModes } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdDelete, MdLink, MdAdd } from 'react-icons/md';

const JobPosting = (props) => {
    const [jobPostings, setJobPostings] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [rowModesModel, setRowModesModel] = useState({});
    const [jobDeleted, setJobDeleted] = useState(false);
    const [jobIdToDelete, setJobIdToDelete] = useState(null);
   
    const columns = [
        { field: 'title', headerName: 'Job Title', flex: 150, editable: true },
        { field: 'city', headerName: 'City', flex: 150, editable: true },
        { field: 'stateAbbrev', headerName: 'State', flex: 50, editable: true },
        { field: 'link', headerName: 'Link', flex: 300, editable: true },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdLink />}
                        label="Direct Link"
                        onClick={() => { window.open(row.link, '_blank'); }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setJobIdToDelete(id); setJobDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal" role="button"
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getJobPostings = async () => {
            apiAdmin.get(`/get-job-postings`)
                .then((response) => {
                    setJobPostings(response.data.map((a) => {
                        return ({ id: a.jobPostingId, title: a.title, city: a.city, stateAbbrev: a.stateAbbrev, link: a.link })
                    }));

                });
        }
        getJobPostings();

    }, [jobDeleted, submitted]);

    function EditToolbar(props) {

        const handleClick = () => {
            setJobPostings((oldRows) => [{ id: -1, title: '', city: '', stateAbbrev: '', link: '' }, ...oldRows]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [-1]: { mode: GridRowModes.Edit, fieldToFocus: 'title' },
            }));
        };

        return (
            <GridToolbarContainer>
                <div className="row w-100">
                    <div className="col">
                        <span className="btn-link btn" onClick={handleClick}>
                            <MdAdd className="mb-1" /> Add Job Posting
                        </span>
                    </div>
                    <div className="col">
                        <GridToolbarQuickFilter className="w-100" />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    const processRowUpdate = (newRow) => {
        if (newRow.stateAbbrev.length !== 2) {
            setError("State abbreviation must be exactly 2 characters.");
            return { ...newRow, isNew: false };
        }

        const jobPostingObject = {
            jobPostingId: newRow.id,
            title: newRow.title,
            city: newRow.city,
            stateAbbrev: newRow.stateAbbrev,
            link: newRow.link,
        }
        const saveJobPosting = async () => {
            await apiAdmin.post(`/save-job-posting`, jobPostingObject)
                .then((response) => {
                    if (response.data) {
                        setSubmitted(true);
                    } else {
                        setError("There was an error saving the job posting. Please try again.")
                    }
                });
        }
        saveJobPosting();
        return { ...newRow, isNew: false };

    };

    const deleteJobPosting = () => {
        const deleteJob = async () => {
            await apiAdmin.post(`/delete-job-posting?JobPostingId=` + jobIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setJobIdToDelete(null);
                        setJobDeleted(true);
                    } else {
                        setError("There was an error deleting the job posting. Please try again.")
                    }
                });
        }
        deleteJob();
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Job Postings</title>
                </Helmet>
                <h1>Job Postings</h1>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Job posting saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {jobDeleted && <div className="alert alert-warning alert-dismissible" role="alert">Job posting deleted successfully!<button type="button" className="btn-close" onClick={() => setJobDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <p>Double click a row to start editing. Click anywhere outside of the table to save the job posting. All fields are required.</p>
                <DataGridPro
                    rows={jobPostings}
                    columns={columns}
                    loading={jobPostings.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={72}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    pagination
                    onRowEditStart={() => { setSubmitted(false); setError(false); } }
                    editMode="row"
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={() => setError("There was an issue updating the job posting. Please try again.")}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(newRowModesModel) => { setRowModesModel(newRowModesModel); } } />
            </div>
            <div className="modal shown" tabIndex="-1" id="deleteModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Job Posting?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this job posting?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteJobPosting()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobPosting;
