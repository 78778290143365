import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarExport, GridToolbarQuickFilter, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { TextField, MenuItem, Tooltip, InputAdornment } from '@mui/material';
import { useForm } from "react-hook-form";
import apiAdmin from '../../services/api-admin';
import { MdHistory, MdHistoryToggleOff, MdAdd } from 'react-icons/md';
import { DateTime } from 'luxon';
import SpinnerLoader from '../layout/spinner-loader';
import { blurOnEnterKey } from '../../services/utilities';

const BucksOneBalances = (props) => {
    const [bucksOneOccasions, setBucksOneOccasions] = useState([]);
    const [bucksOneBalances, setBucksOneBalances] = useState([]);
    const [enableMultiSelect, setEnableMultiSelect] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [savingTransactions, setSavingTransactions] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({defaultValues: { occasion: 'Other' }});

    function HistoryDetailPanelToggle(props) {
        const { value: isExpanded } = props;

        return (
            <Tooltip title={isExpanded ? "Hide History" : "View History"}>
                <div>
                    {isExpanded ? <MdHistoryToggleOff className="lg-grid-button-icon" /> : <MdHistory className="lg-grid-button-icon" />}
                </div>
            </Tooltip>
        );
    }

    function CustomToolbar(props) {
        return (
            <GridToolbarContainer className="d-flex align-items-start">
                <div><GridToolbarExport /></div>
                <div className="flex-grow-1"></div>
                <div className="d-flex flex-column align-items-end">
                    <div className={`form-check form-switch pe-3 ${(!enableMultiSelect || (enableMultiSelect && selectedRows.length === 0)) ? "pb-5" : ""}`}>
                        <input className="form-check-input" type="checkbox" defaultChecked={enableMultiSelect} id="selectMultiple" onChange={(e) => { setEnableMultiSelect(e.target.checked); }} />
                        <label className="form-check-label pt-1" htmlFor="selectMultiple">Select Multiple Team Members?</label>
                    </div>
                    {enableMultiSelect && selectedRows.length > 0 &&
                        <div className="btn btn-link lh-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#addTransactionModal" role="button">
                            <MdAdd className="mb-1" /> Add Transaction to Selected Team Members
                        </div>
                    }
                </div>
                <GridToolbarQuickFilter  {...props.quickFilterProps}/>
            </GridToolbarContainer>
        );
    }
    const columns = [
        { field: 'teamMemberName', headerName: 'Team Member', flex: 1 },
        { field: 'birthday', headerName: 'Birthday', flex: .4, type: 'date', valueGetter: (val) => { return DateTime.fromISO(val).set({ year: 1900 }).toJSDate(); }, valueFormatter: (val) => { return DateTime.fromJSDate(val).toFormat("LLL dd"); } },
        { field: 'anniversaryDate', headerName: 'Anniversary', flex: .4, type: 'date', valueGetter: (val) => { return DateTime.fromISO(val).toJSDate(); } },
        { field: 'balance', headerName: 'Balance', flex: .4, type: 'number', valueFormatter: (val) => { return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); } },
        { field: 'lastUpdated', headerName: 'Last Updated', flex: .5, type: 'date', valueGetter: (val) => { return DateTime.fromISO(val).toJSDate(); } },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            flex: .2,
            align: 'center',
            renderCell: (params) => (
                <HistoryDetailPanelToggle id={params.id} value={params.value} />
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex: .2,
            cellClassName: 'actions',
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Add Transaction"><div><MdAdd /></div></Tooltip>}
                        label="Add Transaction"
                        onClick={() => { setSelectedRowIds([params.row.bucksOneBalanceId]) }}
                        data-bs-toggle="modal"
                        data-bs-target="#addTransactionModal" role="button"
                    />
                ];
            }
        }
    ];

    useEffect(() => {
        const getBucksOneBalances = async () => {
            apiAdmin.get(`/bucksone-balances`)
                .then((response) => {
                    setBucksOneBalances(response.data);
                });
        }

        const getBucksOneOccasions = async () => {
            apiAdmin.get(`/bucksone-occasions`)
                .then((response) => {
                    setBucksOneOccasions(response.data);
                });
        }

        getBucksOneBalances();
        getBucksOneOccasions();
    }, []);

    useEffect(() => {
        if (!bucksOneBalances || bucksOneBalances.length === 0) return;
        var updatedSelectedRows = bucksOneBalances.filter(b => selectedRowIds.includes(b.bucksOneBalanceId));
        setSelectedRows(updatedSelectedRows);
    }, [selectedRowIds, bucksOneBalances]);

    function TransactionHistoryDetailPanelContent({ row }) {
        const [loadingTransactionHistory, setLoadingTransactionHistory] = useState(true);
        const [transactionHistory, setTransactionHistory] = useState([]);

        useEffect(() => {
            const getBucksOneTransactionHistory = async () => {
                apiAdmin.get(`/bucksone-transactions?teamMemberId=${row.teamMemberId}`)
                    .then((response) => {
                        setTransactionHistory(response.data);
                        setLoadingTransactionHistory(false);
                    });
            }
            setLoadingTransactionHistory(true);
            getBucksOneTransactionHistory();


        }, [row.teamMemberId]);

        return (<div className="container">
            {loadingTransactionHistory && <SpinnerLoader />}
            {!loadingTransactionHistory && transactionHistory &&
                <div className="row"><div className="col-12 col-md-8 offset-md-4 col-xl-6 offset-xl-6"><table className="table"><tbody>{transactionHistory.map((t, i) => {
                    return <tr key={i}><td>{t.occasion}</td><td className="text-end">{t.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td><td>{DateTime.fromISO(t.date).toLocaleString(DateTime.DATETIME_SHORT)}</td><td>{t.notes}</td></tr>
                })}</tbody></table></div></div>
            }
        </div>);
    }


    const getRowId = (row) => {
        return row.bucksOneBalanceId;
    }

    const getDetailPanelContent = (params) => <TransactionHistoryDetailPanelContent row={params.row} />;

    const clearTransactionAdditionForm = () => {
        setSelectedRowIds([]); setSubmitted(false); setError(null); reset();
    };

    const saveBucksOneTransaction = data => {
        const bucksOneTransactionObject = {
            ...data,
            teamMemberIds: selectedRows.map(r => r.teamMemberId)
        }
        const saveTransaction = async () => {
            await apiAdmin.post(`/bucksone-save-transaction`, bucksOneTransactionObject)
                .then((response) => {
                    if (response.data) {
                        clearTransactionAdditionForm();
                        setSubmitted(true);
                        var updatedBalances = bucksOneBalances.map((oldbal) => {
                            var updatedBal = response.data.find((newbal) => newbal.bucksOneBalanceId === oldbal.bucksOneBalanceId);
                            return updatedBal ? { ...oldbal, balance: updatedBal.balance, lastUpdated: updatedBal.lastUpdated } : oldbal;
                        });
                        setBucksOneBalances(updatedBalances);
                    } else {
                        setError("There was an error saving the BucksOne transaction. Please try again.")
                    }
                    setSavingTransactions(false);
                });
        }
        setSavingTransactions(true);
        saveTransaction();
    }

    return (
        <div>
            <Helmet>
                <title>BucksOne Balances</title>
            </Helmet>
            <h1>BucksOne Balances</h1>
            <div className="w-100 h-100">
                <DataGridPro
                    rows={bucksOneBalances}
                    columns={columns}
                    getRowId={getRowId}
                    loading={!bucksOneBalances || bucksOneBalances.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                        toolbar: {
                            printOptions: { disableToolbarButton: true },
                            quickFilterProps: {
                                    onKeyDown: blurOnEnterKey
                                }
                        },                           
                    }} 
                    autoHeight
                    checkboxSelection={enableMultiSelect} 
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    onRowSelectionModelChange={(rowIdsSelected) => {
                        setSelectedRowIds(rowIdsSelected);
                    }}
                    rowSelectionModel={selectedRowIds}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                        sorting: { sortModel: [{ field: 'teamMemberName', sort: 'asc' }] }
                    }}
                    pagination
                    slots={{ toolbar: CustomToolbar }}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={({ row }) => 'auto'}
                />
            </div>
            <div className="modal" tabIndex="-1" aria-hidden="true" id="addTransactionModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Transaction</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={clearTransactionAdditionForm} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {submitted && <><div className="alert alert-success alert-dismissible" role="alert">BucksOne Transactions have been added successfully!</div></>}
                            {error && <div className="alert alert-danger" role="alert">{error}</div>}
                            {savingTransactions && <SpinnerLoader />}
                            {!submitted && !savingTransactions && <form onSubmit={handleSubmit(saveBucksOneTransaction)} >
                                    <p className="lh-sm text-small">Adding to {selectedRows.map((r, i) => { return r.teamMemberName; }).sort().join(", ")}</p>
                                    <div className="row row-cols-1">
                                        <div className="col mb-3">
                                            <TextField
                                                select
                                                fullWidth
                                                {...register("occasion")}
                                                value={watch("occasion")}
                                                label="Occasion"
                                            >
                                                {bucksOneOccasions.map((o, i) => {
                                                    return <MenuItem key={i} value={o.occasion}>
                                                        {o.occasion}
                                                    </MenuItem>
                                                })}
                                            </TextField>
                                        </div>
                                        <div className="col mb-3">
                                            <TextField
                                                label="Amount *"
                                                autoComplete="off"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                {...register("amount", { required: true, pattern: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(\.[0-9]{2})*$/ })}
                                            />
                                            {errors?.amount?.type === "required" && <small className="text-danger d-block">This field is required</small>}
                                            {errors?.amount?.type === "pattern" && <small className="text-danger d-block">Please enter numeric dollars/cents value.</small>}
                                        </div>
                                        <div className="col mb-3">
                                            <TextField
                                                {...register("notes")}
                                                label="Notes"
                                            />
                                        </div>
                                        <div className="col mb-3">
                                            <button type="submit" className="btn btn-primary me-4">Submit</button>
                                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={clearTransactionAdditionForm}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default BucksOneBalances;
