/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';
const SiteMessages = (props) => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control  } = useForm();
    const [siteMessages, setSiteMessages] = useState([]);
    const [siteMessageIdToEdit, setSiteMessageIdToEdit] = useState(null);
    const [siteMessageToEdit, setSiteMessageToEdit] = useState(null);
    const [siteMessageIdToDelete, setSiteMessageIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([]);
    const [regions, setRegions] = useState([]);
    const [pages, setPages] = useState([]);

    const columns = [
        { field: 'startDateTime', headerName: 'Start Date/Time', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ')}},
        { field: 'endDateTime', headerName: 'End Date/Time', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ')} },
        { field: 'message', headerName: 'Message', flex: 600 },
        { field: 'active', headerName: 'Active', flex: 75 },

        { field: 'brandName', headerName: 'Brands', flex: 200 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setSiteMessageIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setSiteMessageIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getSiteMessages = async () => {
            apiAdmin.get(`/site-messages`)
                .then((response) => {
                    setSiteMessages(response.data.map((a) => ({ id: a.siteMessageId, message: a.message, active: (a.active ? "Yes" : "No"), brandName: a.siteMessageBrands.map(m => m.brand.brandName).join(', '), startDateTime: new Date(a.startDateTime), endDateTime: new Date(a.endDateTime) })));

                });
        }

        getSiteMessages();

        const getBrands = async () => {
            apiAdmin.get(`/brands`)
                .then((response) => {
                    setBrands(response.data.filter(b => b.brandId !== 4));
                });
        }
        getBrands();

        const getRegions = async () => {
            apiAdmin.get(`/regions`)
                .then((response) => {
                    setRegions(response.data);
                });
        }
        getRegions();

        const getPages = async () => {
            apiAdmin.get(`/pages`)
                .then((response) => {
                    setPages(response.data);
                });
        }
        getPages();

    }, [submitted, deleted]);



    useEffect(() => {
        if (!siteMessageIdToEdit || siteMessageIdToEdit === -1) return;
        const getSiteMessage = async () => {
            apiAdmin.get(`/site-message?siteMessageId=${siteMessageIdToEdit}`)
                .then((response) => {
                    setSiteMessageToEdit(response.data);
                    setValue('active', response.data.active);
                    setValue('startDateTime', DateTime.fromISO(response.data.startDateTime));
                    setValue('endDateTime', DateTime.fromISO(response.data.endDateTime));
                    setValue('message', response.data.message);
                    setValue('allUsers', response.data.allUsers);
                    response.data.siteMessageBrands.forEach((brand) => {
                        setValue(brands.find(b => b.brandId === brand.brandId).brandName.replaceAll(' ', ''), true);
                    });
                    response.data.siteMessageRegions.forEach((region) => {
                        setValue(regions.find(b => b.regionId === region.regionId).regionName.replaceAll(' ', ''), true);
                    });
                    response.data.siteMessagePages.forEach((page) => {
                        setValue(pages.find(b => b.pageId === page.pageId).pagePath.replaceAll(' ', ''), true);
                    });
                });
        }
        getSiteMessage();
    }, [siteMessageIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setSiteMessageIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Site Message
                </span>
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        var siteMessageBrands = [];
        brands.forEach((brand) => {
            if (data[brand.brandName.replaceAll(' ', '')]) siteMessageBrands.push({ brandId: brand.brandId })
        });
        var siteMessageRegions = [];
        regions.forEach((region) => {
            if (data[region.regionName.replaceAll(' ', '')]) siteMessageRegions.push({ regionId: region.regionId })
        });
        var siteMessagePages = [];
        pages.forEach((page) => {
            if (data[page.pagePath]) siteMessagePages.push({ pageId: page.pageId })
        });
        const siteMessageObject = {
            ...data,
            siteMessageBrands,
            siteMessageRegions,
            siteMessagePages,
            siteMessageId: siteMessageIdToEdit === -1 ? null : siteMessageIdToEdit,
            createdByAdminId: 1
        }
        const saveSiteMessage = async () => {
            await apiAdmin.post(`/save-site-message`, siteMessageObject)
                .then((response) => {
                    if (response.data) {
                        setSiteMessageIdToEdit(null);
                        setSiteMessageToEdit(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the site message. Please try again.")
                    }
                });
        }
        saveSiteMessage();
    }

    const deleteSiteMessage = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-site-message?siteMessageId=`+ siteMessageIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setSiteMessageIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the site message. Please try again.")
                    }
                });
        }
        deleteMessage();
    }
    
    return (
        <div>
            <Helmet>
                <title>Site Messages</title>
            </Helmet>
            <h1>Site Messages</h1>
            
            {siteMessageToEdit || siteMessageIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <label className="form-check-label" htmlFor="active">Active</label>
                                <input className="form-check-input" type="checkbox" id="active" {...register("active")}  />
                            </div>
                        </div>
                        <hr/>
                        <div className="mb-3">
                            {brands && brands.map((brand, index) => { 
                                return <div className="form-check form-switch" key={'check_'+index}>
                                    <input className="form-check-input" type="checkbox" id={brand.brandName.replaceAll(' ', '')} {...register(brand.brandName.replaceAll(' ', ''))}  />
                                    <label className="form-check-label" htmlFor={brand.brandName.replaceAll(' ', '')}>{brand.brandName}</label>
                                </div>
                            })}
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="startDateTime"
                                control={control}
                                rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("startDateTime")).isValid}}}
                                render={({ field: { onChange, value } }) => (
                                    <DateTimePicker  value={value} onChange={onChange} className="w-100" label="Start Date/Time"/>
                                )}
                            />
                            {errors?.startDateTime?.type === "dateTimeValid" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="endDateTime"
                                control={control}
                                rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("endDateTime")).isValid}}}
                                render={({ field: { onChange, value } }) => (
                                    <DateTimePicker  value={value} onChange={onChange} className="w-100" label="End Date/Time"/>
                                )}
                            />
                            {errors?.endDateTime?.type === "dateTimeValid" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Message *"
                                {...register("message", {required: true})}
                                className="w-100"
                            />
                            {errors?.message?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="allUsers" {...register("allUsers")}  />
                                <label className="form-check-label" htmlFor="allUsers">Show for all users (will override regions)</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <OutlinedDiv label="Regions Enabled For">
                                {regions && regions.map((region, index) => { 
                                        return <div className="form-check form-switch" key={'check_'+index}>
                                            <input className="form-check-input" type="checkbox" id={region.regionName.replaceAll(' ', '')} {...register(region.regionName.replaceAll(' ', ''))}  />
                                            <label className="form-check-label" htmlFor={region.regionName.replaceAll(' ', '')}>{region.regionName}</label>
                                        </div>
                                    })}
                            </OutlinedDiv>
                        </div>
                        <div className="mb-3">
                            <OutlinedDiv label="Pages Enabled For">
                                {pages && pages.map((page, index) => { 
                                    return <div className="form-check form-switch" key={'check_'+index}>
                                        <input className="form-check-input" type="checkbox" id={page.pagePath} {...register(page.pagePath)}  />
                                        <label className="form-check-label" htmlFor={page.pagePath}>{page.pagePath}</label>
                                    </div>
                                })}
                            </OutlinedDiv>
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setSiteMessageIdToEdit(null); setSiteMessageToEdit(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Site Message saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Site Message deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={siteMessages}
                    columns={columns}
                    loading={siteMessages.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Site Message?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this site message?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteSiteMessage()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SiteMessages;
