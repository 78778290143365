/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd, MdSearch } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { Editor } from 'react-draft-wysiwyg';
import './../styles/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import { FilePath, FormatHTMLForWYSIWYGEditor } from '../../services/utilities';
import "./item-of-the-week.scss";
import { TextField} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';
import { useOktaAuth } from '@okta/okta-react';

const ItemOfTheWeeks = (props) => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control  } = useForm();
    const { authState } = useOktaAuth();
    const [itemOfTheWeeks, setItemOfTheWeeks] = useState([]);
    const [itemOfTheWeekIdToEdit, setItemOfTheWeekIdToEdit] = useState(null);
    const [itemOfTheWeekToEdit, setItemOfTheWeekToEdit] = useState(null);
    const [itemOfTheWeekIdToDelete, setItemOfTheWeekIdToDelete] = useState(null);
    const [itemOfTheWeekIdToPreview, setItemOfTheWeekIdToPreview] = useState(null);
    const [itemOfTheWeekToPreview, setItemOfTheWeekToPreview] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [regions, setRegions] = useState([]);
    const [imageDropzone, setImageDropzone] = useState(null);
    const [textEditorState, setTextEditorState] = useState(
        () => EditorState.createEmpty(),
    )
    const [titleEditorState, setTitleEditorState] = useState(
        () => EditorState.createEmpty(),
    )


    const columns = [
        { field: 'title', headerName: 'Title', flex: 250, display: 'flex' , renderCell: function (t) { return <span dangerouslySetInnerHTML={{ __html: t.value }}></span>} },
        { field: 'text', headerName: 'Text', flex: 650, renderCell: function (t) { return <span dangerouslySetInnerHTML={{ __html: t.value }}></span>}},
        { field: 'activeDate', headerName: 'Active Date', flex: 150, renderCell: function (t) { return t.value.toLocaleDateString("en-US")} },
        { field: 'endDate', headerName: 'End Date', flex: 150, renderCell: function (t) { return t.value.toLocaleDateString("en-US")} },
        { field: 'brandName', headerName: 'Brands', flex: 200, display: 'flex'  },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 150,
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setItemOfTheWeekIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setItemOfTheWeekIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                    <GridActionsCellItem
                        icon={<MdSearch />}
                        label="Preview"
                        onClick={() => { setItemOfTheWeekIdToPreview(id)}}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#previewModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getItemOfTheWeeks = async () => {
            apiAdmin.get(`/item-of-the-weeks`)
                .then((response) => {
                    setItemOfTheWeeks(response.data.map((a) => ({ id: a.itemOfTheWeekId, title: a.title, text: a.text, brandName: a.itemOfTheWeekBrands.map(m => m.brand.brandName).join(', '), activeDate: new Date(a.activeDate), endDate: new Date(a.endDate) })));

                });
        }

        getItemOfTheWeeks();

        const getBrands = async () => {
            apiAdmin.get(`/brands`)
                .then((response) => {
                    setBrands(response.data.filter(b => b.brandId !== 4));
                });
        }
        getBrands();

        const getRegions = async () => {
            apiAdmin.get(`/regions`)
                .then((response) => {
                    setRegions(response.data);
                });
        }
        getRegions();
    }, [submitted, deleted]);

    useEffect(() => {
        if (itemOfTheWeekIdToEdit === -1) setSelectedBrands([]);
        if (!itemOfTheWeekIdToEdit || itemOfTheWeekIdToEdit === -1) return;
        const getItemOfTheWeek = async () => {
            apiAdmin.get(`/item-of-the-week?itemOfTheWeekId=${itemOfTheWeekIdToEdit}`)
                .then((response) => {

                    setImageDropzone(null);
                    setItemOfTheWeekToEdit(response.data);
                    setValue('title', response.data.title);
                    setValue('text', response.data.text);
                    setValue('buttonText', response.data.buttonText);
                    setValue('buttonURL', response.data.buttonURL);
                    setValue('activeDate', DateTime.fromISO(response.data.activeDate));
                    setValue('endDate', DateTime.fromISO(response.data.endDate));
                    var tempSelectedBrands = [];
                    response.data.itemOfTheWeekBrands.forEach((brand) => {
                        var tempBrand = brands.find(b => b.brandId === brand.brandId);
                        setValue(tempBrand.brandName.replaceAll(' ', ''), true);
                        tempSelectedBrands.push(tempBrand);
                    });
                    setSelectedBrands(tempSelectedBrands);

                    regions.forEach((region) => {
                        if (response.data.itemOfTheWeekRegions.map(i => i.regionId).includes(region.regionId)) {
                            setValue(region.regionName.replaceAll(' ', ''), true);
                        } else {
                            setValue(region.regionName.replaceAll(' ', ''), false);
                        }
                    });
                });
        }
        getItemOfTheWeek();
    }, [itemOfTheWeekIdToEdit]);

    useEffect(() => {
        if (!itemOfTheWeekIdToPreview) return;
        const getItemOfTheWeek = async () => {
            apiAdmin.get(`/item-of-the-week?itemOfTheWeekId=${itemOfTheWeekIdToPreview}`)
                .then((response) => {
                    setItemOfTheWeekToPreview(response.data);
                });
        }
        getItemOfTheWeek();
    }, [itemOfTheWeekIdToPreview]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setItemOfTheWeekIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Item of the Week
                </span>
            </GridToolbarContainer>
        );
    }
    const onSubmit = data => {
        var itemOfTheWeekBrands = [];
        var itemOfTheWeekRegions = [];
        selectedBrands.forEach((brand) => {
            if (data[brand.brandName.replaceAll(' ', '')]) itemOfTheWeekBrands.push({ brandId: brand.brandId })
            brand.regions.forEach((region) => {
                if (data[region.regionName.replaceAll(' ', '')]) itemOfTheWeekRegions.push({ regionId: region.regionId })
            });
        });

        const itemOfTheWeekObject = {
            ...data,
            itemOfTheWeekBrands,
            itemOfTheWeekRegions,
            itemOfTheWeekId: itemOfTheWeekIdToEdit,
            text: draftToHtml(convertToRaw(textEditorState.getCurrentContent())),
            title: draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
            photo: imageDropzone.getAcceptedFiles().length > 0 ? imageDropzone.getAcceptedFiles()[0].name : '',
        }
        const saveItemOfTheWeek = async () => {
            await apiAdmin.post(`/save-item-of-the-week`, itemOfTheWeekObject)
                .then((response) => {
                    if (response.data) {
                        setItemOfTheWeekIdToEdit(null);
                        setItemOfTheWeekToEdit(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the item of the week. Please try again.")
                    }
                });
        }
        saveItemOfTheWeek();
    }

    const deleteItemOfTheWeek = () => {
        const deleteArticle = async () => {
            await apiAdmin.post(`/delete-item-of-the-week?itemOfTheWeekId=`+ itemOfTheWeekIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setItemOfTheWeekIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the item of the week. Please try again.")
                    }
                });
        }
        deleteArticle();
    }

    useEffect(() => {
        if (itemOfTheWeekToEdit || itemOfTheWeekIdToEdit === -1) {
            setSubmitted(false);
            if (imageDropzone) imageDropzone.disable();
            setImageDropzone(new Dropzone("div#photo", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/item-of-the-week/image", acceptedFiles: '.webp, .gif', createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
            if (itemOfTheWeekToEdit) {
                setTextEditorState(FormatHTMLForWYSIWYGEditor(itemOfTheWeekToEdit.text));  
                setTitleEditorState(FormatHTMLForWYSIWYGEditor(itemOfTheWeekToEdit.title));
            } else { 
                setTextEditorState(EditorState.createEmpty());
                setTitleEditorState(EditorState.createEmpty());
            }
        }
    }, [itemOfTheWeekToEdit, itemOfTheWeekIdToEdit]);

    //to display image in the dropzone area, doesn't work because of cors issue

    //useEffect(() => {
    //    if (!imageDropzone || !itemOfTheWeekToEdit) return; 
    //    let mockFile = { name: "Filename", size: 12345 };
    //    imageDropzone.displayExistingFile(mockFile, FilePath('/images/ItemOfTheWeek/' + itemOfTheWeekToEdit.photo), null, "anonymous");
    //}, [imageDropzone]);

    const toggleSelectedBrand = (brand) => {
        if (selectedBrands.includes(brand)) {
            setSelectedBrands([...selectedBrands].filter(f => f.brandId !== brand.brandId))
        } else {
            var tempSelectedBrands = [...selectedBrands];
            tempSelectedBrands.push(brand);
            setSelectedBrands(tempSelectedBrands)       
        }
    }

    return (
        <div>
            <Helmet>
                <title>Item of the Week</title>
            </Helmet>
            <h1>Item of the Week</h1>
            
            {itemOfTheWeekToEdit || itemOfTheWeekIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <OutlinedDiv label="Title">
                                <Editor
                                    editorState={titleEditorState}
                                    editorClassName="wysiwyg-title"
                                    onEditorStateChange={(e) => setTitleEditorState(e)}
                                    />
                            </OutlinedDiv>
                        </div>
                        <div className="mb-3">
                            {brands && brands.map((brand, index) => { 
                                return <div className="form-check form-switch" key={'check_'+index}>
                                    <input className="form-check-input" type="checkbox" id={brand.brandName.replaceAll(' ', '')} {...register(brand.brandName.replaceAll(' ', ''))} onClick={() => toggleSelectedBrand(brand)} />
                                    <label className="form-check-label" htmlFor={brand.brandName.replaceAll(' ', '')}>{brand.brandName}</label>
                                </div>
                            })}
                        </div>
                        {selectedBrands.length > 0 && <div className="mb-3">
                            <OutlinedDiv label="Regions Enabled For">
                            {selectedBrands.map(b => b.regions).flat().map((region, index) => {
                                return <div className="form-check form-switch" key={'check_' + index}>
                                    <input className="form-check-input" type="checkbox" defaultChecked={itemOfTheWeekIdToEdit === -1} id={region.regionName.replaceAll(' ', '')} {...register(region.regionName.replaceAll(' ', ''))} />
                                    <label className="form-check-label" htmlFor={region.regionName.replaceAll(' ', '')}>{region.regionName}</label>
                                </div>
                            })}
                            </OutlinedDiv>
                        </div>}
                        <div className="mb-3">
                            <OutlinedDiv label="Body">
                                <Editor
                                editorState={textEditorState}
                                editorClassName="wysiwyg"
                                onEditorStateChange={(e) => setTextEditorState(e)}
                                />
                            </OutlinedDiv>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Button Text *"
                                {...register("buttonText", {required: true})}
                                className="w-100"
                            />
                            {errors?.buttonText?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Button URL *"
                                {...register("buttonURL", {required: true})}
                                className="w-100"
                            />
                            {errors?.buttonURL?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <OutlinedDiv label="Image">
                                <div id="photo" className="dropzone dropzone-previews border-0">                                        
                                    <div className="dz-message" data-dz-message><span>Image Upload</span></div>
                                </div>
                            </OutlinedDiv>
                            <small>Only .webp and .gif files are allowed.</small>
                        </div>
                        <div className="mb-3">
                            {itemOfTheWeekToEdit && itemOfTheWeekToEdit.photo && <img src={FilePath('/images/ItemOfTheWeek/' + itemOfTheWeekToEdit.photo)} width="300" alt='Item of the Week'/> }
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="activeDate"
                                control={control}
                                rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("activeDate")).isValid}}}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker value={value} onChange={onChange} className="w-100" label="Active Date *" />
                                )}
                            />
                            {errors?.activeDate?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="endDate"
                                control={control}
                                rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("endDate")).isValid}}}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker value={value} onChange={onChange} className="w-100" label="End Date *" />
                                )}
                            />
                            {errors?.endDate?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setItemOfTheWeekIdToEdit(null); setItemOfTheWeekToEdit(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Item of the Week saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Item of the Week deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={itemOfTheWeeks}
                    columns={window.screen.width > 600 ? columns : columns.filter(f => f.field !== "text" && f.field !== "brandName")}
                    loading={itemOfTheWeeks.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Item of the Week?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this item of the week?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteItemOfTheWeek()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal iotw" tabIndex="-1" aria-hidden="true" id="previewModal">
                <div className="modal-dialog">
                    {itemOfTheWeekToPreview &&
                        <div className="white-popup">
                            <div className="iotw-border">
                                <button type="button" data-bs-dismiss="modal" className="btn-close opacity-100 iotw-close bg-primary" aria-label="Close" />
                                <div className="modal_left d-flex align-items-center p-3">
                                    <div className="w-100">
                                        <div className="modal_h1" dangerouslySetInnerHTML={{ __html: itemOfTheWeekToPreview.title}}></div>
                                        <div className="no-localization" dangerouslySetInnerHTML={{ __html: itemOfTheWeekToPreview.text }}></div>
                                        <div className="text-center mt-2">
                                            <a className="btn btn-primary" href={itemOfTheWeekToPreview.buttonURL} aria-label="Item of the week clickable button">
                                                <span dangerouslySetInnerHTML={{ __html: itemOfTheWeekToPreview.buttonText }}></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal_right mh-100">
                                    <a className="w-100 float-end" href={itemOfTheWeekToPreview.buttonURL} >
                                        <img className="iotw-image" src={FilePath("/images/ItemOfTheWeek/" + itemOfTheWeekToPreview.photo)} alt="Item Of The Week" />
                                    </a>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>   
        </div>
    );
}

export default ItemOfTheWeeks;
