import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import MarketShareDataEntry from './market-share-data-entry';
import { useForm, Controller } from "react-hook-form"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, MenuItem, ListSubheader } from '@mui/material';
import apiAdmin from '../../services/api-admin';
import SpinnerLoader from '../layout/spinner-loader';
import { DateTime } from 'luxon';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

const MarketShare = (props) => {
    const { handleSubmit, control, watch, setValue, formState: { errors } } = useForm()
    const [regionStateCounties, setRegionStateCounties] = useState();
    const [countyLoanTypesAndBuckets, setCountyLoanTypesAndBuckets] = useState();
    const [formSelections, setFormSelections] = useState();
    const [companies, setCompanies] = useState();
    const defaultSelectedDate = DateTime.now().minus({ months: 1 });

    const watchRegionStateCountyId = watch("regionStateCountyId");
    const watchLoanTypeId = watch("loanTypeId");
    const watchMonthYear = watch("monthYear");

    useEffect(() => {
        const getRegionStateCounties = async () => {
            apiAdmin.get(`/market-share/counties`)
                .then((response) => {
                    var groupedByState = groupBy(orderBy(response.data, ['stateFullName', 'county']), 'stateFullName');
                    setRegionStateCounties(groupedByState);
                });
        };
        getRegionStateCounties();
    }, []);

    useEffect(() => {
        if (!watchRegionStateCountyId || watchRegionStateCountyId === "-1") return;

        const getCountyLoanTypesAndBuckets = async () => {
            apiAdmin.get(`/market-share/counties/${watchRegionStateCountyId}/loantypes`)
                .then((response) => {
                    setCountyLoanTypesAndBuckets(response.data);
                    if (response.data.length === 1) {
                        setValue("loanTypeId", response.data[0].loanTypeId);
                    }
                    else {
                        setValue("loanTypeId", "-1");
                    }
                });
        };
        getCountyLoanTypesAndBuckets()
        setCompanies();

    }, [watchRegionStateCountyId]);

    useEffect(() => {
        setCompanies();
    }, [watchLoanTypeId]);

    useEffect(() => {
        setFormSelections();
    }, [watchRegionStateCountyId, watchLoanTypeId, watchMonthYear]);

    const renderSelectGroup = (state, counties, groupIndex) => {
        const items = counties.map((c, i) => {
            return (
                <MenuItem key={`${groupIndex}-${i}`} value={c.regionStateCountyId}>
                    {c.county}
                </MenuItem>
            );
        });        
        return [<ListSubheader>{state}</ListSubheader>, items];
    };

    const buildDataEntryTable = data => {
        const getCompanies = async () => {
            apiAdmin.get(`/market-share/counties/${data.regionStateCountyId}/companies`)
                .then((response) => {
                    setCompanies(response.data);
                    setFormSelections(data);
                });
        };

        getCompanies();
    };

    return (
        <div>
            <Helmet>
                <title>Market Share</title>
            </Helmet>
            <h1>Market Share Data</h1>
            <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
                <div className="pt-2">
                    <h2>Data Entry:</h2>
                    {!regionStateCounties && <SpinnerLoader />}
                    {regionStateCounties &&
                        <div>
                            <form onSubmit={handleSubmit(buildDataEntryTable)}>
                                <div className="mb-3">
                                    <Controller
                                        name="regionStateCountyId"
                                        id="regionStateCountyId"
                                        control={control}
                                        defaultValue="-1"
                                        rules={{
                                            min:0
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                label="County"
                                                className="w-100"
                                                {...field}>
                                                <MenuItem value="-1">- Select -</MenuItem>
                                                {regionStateCounties && Object.entries(regionStateCounties).map(([state, counties], i) => {
                                                    return renderSelectGroup(state, counties, i);
                                                })}
                                            </TextField>
                                        )} />
                                    {errors?.regionStateCountyId?.type === "min" && <small className="text-danger">Please select the county.</small>}
                                </div>
                                <div className="mb-3">
                                    <Controller
                                        name="loanTypeId"
                                        id="loanTypeId"
                                        control={control}
                                        defaultValue="-1"
                                        disabled={!countyLoanTypesAndBuckets || countyLoanTypesAndBuckets?.length === 0}
                                        rules={{
                                            min: 0,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                label="Loan Type"
                                                className="w-100"
                                                {...field}>
                                                {(!countyLoanTypesAndBuckets || countyLoanTypesAndBuckets?.length > 1) && <MenuItem value="-1" key="-1" >- Loan Type -</MenuItem>}
                                                {countyLoanTypesAndBuckets && countyLoanTypesAndBuckets.map((lt, i) => {
                                                    return <MenuItem key={i} value={lt.loanTypeId}>{lt.name}</MenuItem>
                                                    })                                                    
                                                }
                                            </TextField>
                                        )} />
                                    {errors?.loanTypeId?.type === "min" && <small className="text-danger">Please select a loan type.</small>}
                                </div>
                                <div className="mb-3">
                                    <Controller
                                        name="monthYear"
                                        id="monthYear"
                                        control={control}
                                        disabled={!countyLoanTypesAndBuckets || countyLoanTypesAndBuckets?.length === 0}
                                        defaultValue={defaultSelectedDate}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                variant="inline"
                                                openTo="year"
                                                views={["year", "month"]}
                                                label="Year and Month"
                                                helperText="Start from year selection"
                                            />
                                        )} />
                                </div>

                                <div className="mb-3">
                                    <input type="submit" value="Build Data Entry Table" className="btn btn-primary" aria-label="Build Data Entry Table" />
                                </div>
                            </form>

                            <MarketShareDataEntry formSelections={formSelections}
                                countyLoanTypeBuckets={countyLoanTypesAndBuckets?.find(c => c.loanTypeId === watchLoanTypeId)?.countyLoanTypeBuckets}
                                companies={companies} />
                        </div>
                    }

                </div>
                <div className="pe-5">
                    <h2>Reports: </h2>
                    <ul>
                        <li><a href="http://dpwttosqlint01/Reports/report/Market%20Share/MarketShare" rel="noreferrer" target="_blank">Market Share Data</a></li>
                        <li><a href="http://dpwttosqlint01/reports/report/Market%20Share/CondensedMarketShare" rel="noreferrer" target="_blank">Market Share Condensed</a></li>
                    </ul>
                </div>
            </div>
        </div>

    );
}

export default MarketShare;
