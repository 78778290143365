/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdAdd } from 'react-icons/md';
import { useForm, Controller} from "react-hook-form";
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { FilePath, } from '../../services/utilities';
import { TextField, MenuItem, FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import OutlinedDiv from '../layout/OutlinedDiv';
import { useOktaAuth } from '@okta/okta-react';

const PromoItemManagement = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm();
    const { authState } = useOktaAuth();
    const [items, setItems] = useState([]);
    const [itemIdToEdit, setItemIdToEdit] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [imageDropzone, setImageDropzone] = useState(null);
    const [selectedZones, setSelectedZones] = useState([]);
    const [eligibleZones, setEligibleZones] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const columns = [
        { field: 'itemName', headerName: 'Item Name', flex: 200 },
        { field: 'price', headerName: 'Item Price', flex: 150, type: 'number', valueFormatter: (val) => { return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); } },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => { setItemIdToEdit(id) }}
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getItems = async () => {
            apiAdmin.get(`/get-promo-items`)
                .then((response) => {
                    setItems(response.data.map((a) => ({ id: a.promoItemId, itemName: a.itemName, price: a.price, discontinued: a.discontinued })));
                });
        }
        getItems();

        const getEligibleZones = async () => {
            apiAdmin.get(`/promo-order-zones`)
                .then((response) => {
                    setEligibleZones(response.data);
                });
        }
        getEligibleZones();

    }, [submitted]);

    useEffect(() => {
        if (!itemIdToEdit || itemIdToEdit === -1) return;
        const getPromoItems = async () => {
            apiAdmin.get(`/get-promo-item?promoItemID=${itemIdToEdit}`)
                .then((response) => {
                    setImageDropzone(null);
                    setItemToEdit(response.data);
                    setValue('itemName', response.data.itemName);
                    setValue('price', response.data.price);
                    setValue('notes', response.data.notes);
                    setValue('minimumOrderQuantity', response.data.minimumOrderQuantity);
                    setValue('new', response.data.new);
                    setValue('discontinued', response.data.discontinued);
                    setSelectedZones(response.data.eligibleZones);
                });
        }
        getPromoItems();
    }, [itemIdToEdit]);

    useEffect(() => {
        if (itemToEdit || itemIdToEdit === -1) {
            setSubmitted(false);
            if (imageDropzone) imageDropzone.disable();
            setImageDropzone(new Dropzone("div#image", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/promo-item-management/image", acceptedFiles: '.webp, .jpg', createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, headers: { 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
        }
    }, [itemToEdit, itemIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer className="d-flex align-items-end justify-content-between">
                <span className="btn-link btn" onClick={() => { setItemIdToEdit(-1) }}>
                    <MdAdd className="mb-1" /> Add New Item
                </span>
                <GridToolbarQuickFilter className="ml-auto" />
            </GridToolbarContainer>
        );
    }

    const getRowClassName = (params) => {
        return params.row.discontinued ? 'bg-primary text-light' : '';
    };

    const handleZoneChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedZones(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onSubmit = data => {
        const promoItemObject = {
            ...data,
            photo: imageDropzone.getAcceptedFiles().length > 0 ? imageDropzone.getAcceptedFiles()[0].name : '',
            promoItemId: itemIdToEdit
        }

        const saveItems= async () => {
            await apiAdmin.post(`/save-promo-item`, promoItemObject)
                .then((response) => {
                    if (response.data) {
                        setItemIdToEdit(null);
                        setItemToEdit(null);
                        setSubmitted(true);
                        reset();
                        setSelectedZones([]);
                    } else {
                        setError("There was an error saving the item. Please try again.")
                    }
                });
        }
        saveItems();
    }

        return (
            <div>
                <Helmet>
                    <title>Promo Item Management</title>
                </Helmet>
                <h1>Promo Item Management</h1>

                {itemIdToEdit || itemIdToEdit === -1 ? <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="border p-3">
                            <div className="mb-3">
                                <TextField
                                    label="Item Name  *"
                                    {...register("itemName", { required: true })}
                                    className="w-100"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors?.itemName?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <TextField
                                            label="Minimum Quantity *"
                                            {...register("minimumOrderQuantity", { required: true })}
                                            className="w-100"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors?.minimumOrderQuantity?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            label="Item Price *"
                                            {...register("price", { required: true })}
                                            className="w-100"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors?.price?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <TextField
                                    label="Notes"
                                    {...register("notes")}
                                    className="w-100"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check form-switch">
                                            <label className="form-check-label" htmlFor="new">New?</label>
                                            <input className="form-check-input" type="checkbox" id="new" {...register("new")} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check form-switch">
                                            <label className="form-check-label" htmlFor="discontinued">Discontinued?</label>
                                            <input className="form-check-input" type="checkbox" id="discontinued" {...register("discontinued")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <OutlinedDiv label="Image">
                                    <div id="image" className="w-100 dropzone dropzone-previews border-0">
                                        <div className="dz-message" data-dz-message><span>Image Upload</span></div>
                                    </div>
                                </OutlinedDiv>
                            </div>
                            <div className="mb-3">
                                {itemToEdit && itemToEdit.photo && <img src={FilePath('/promoitemimages/' + itemToEdit.photo)} width="300" alt='Promo Item' />}
                            </div>
                        </div>
                        <div className="mt-3 col-6">
                            <FormControl fullWidth error={!!errors.eligibleZones}>
                                <InputLabel id="multiple-zone-label">Eligible Zones</InputLabel>
                                <Controller
                                    name="eligibleZones"
                                    control={control}
                                    defaultValue={selectedZones.length > 0 ? selectedZones : eligibleZones.map(option => option.promoOrderZoneId)}
                                    rules={{ required: 'At least one zone is required' }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            labelId="multiple-zone-label"
                                            id="multiple-zone"
                                            multiple
                                            value={selectedZones.length > 0 ? selectedZones : eligibleZones.map(option => option.promoOrderZoneId)}
                                            onChange={e => {
                                                field.onChange(e);
                                                handleZoneChange(e);
                                            }}
                                            input={<OutlinedInput label="Eligible Zones" />}
                                        >
                                            {eligibleZones.map(option => (
                                                <MenuItem key={option.promoOrderZoneId} value={option.promoOrderZoneId}>
                                                    {option.zoneName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.eligibleZones && <p>{errors.eligibleZones.message}</p>}
                            </FormControl>
                        </div>
                        <div className="pt-2">
                            <button type="submit" className="btn btn-primary me-4">Submit</button>
                            <button type="button" className="btn btn-primary" onClick={() => { setItemIdToEdit(null); setItemToEdit(null); setSubmitted(false); setError(null); reset(); setSelectedZones([]); }}>Cancel</button>
                        </div>
                    </form>
                    </>
                    :
                    <>
                        {submitted && <div className="alert alert-success alert-dismissible" role="alert">Item saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
                        {error && <div className="alert alert-danger" role="alert">{error}</div>}
                        <DataGridPro
                            rows={items}
                            columns={columns}
                            loading={items.length === 0}
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'skeleton',
                                    noRowsVariant: 'skeleton',
                                },
                            }}
                            autoHeight
                            rowHeight={38}
                            disableRowSelectionOnClick
                            alignItems="center"
                            slots={{
                                toolbar: EditToolbar,                              
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                },
                            }}
                            pagination
                            getRowClassName={getRowClassName}
                        />
                    </>
                }
            </div>
        );
}

export default PromoItemManagement;
