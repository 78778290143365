/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, Controller} from "react-hook-form";
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { Editor } from 'react-draft-wysiwyg';
import './../styles/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import './news-articles.scss';
import { FilePath, FormatHTMLForWYSIWYGEditor, } from '../../services/utilities';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';
import { useOktaAuth } from '@okta/okta-react';

const NewsArticles = (props) => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control  } = useForm();
    const { authState } = useOktaAuth();
    const [newsArticles, setNewsArticles] = useState([]);
    const [newsArticleIdToEdit, setNewsArticleIdToEdit] = useState(null);
    const [newsArticleToEdit, setNewsArticleToEdit] = useState(null);
    const [newsArticleIdToDelete, setNewsArticleIdToDelete] = useState(null);
    const [imageDropzone, setImageDropzone] = useState(null);
    const [fileDropzone, setFileDropzone] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([]);
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    )

    const columns = [
        { field: 'title', headerName: 'Title', flex: 750},
        { field: 'activeDate', headerName: 'Date', flex: 150, renderCell: function (t) { return t.value.toLocaleDateString("en-US")} },
        { field: 'brandName', headerName: 'Brands', flex: 200 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setNewsArticleIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setNewsArticleIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
        ];


    useEffect(() => {
        const getNewsArticles = async () => {
            apiAdmin.get(`/news-articles`)
                .then((response) => {
                    setNewsArticles(response.data.map((a) => ({ id: a.newsArticleId, title: a.title, brandName: a.newsArticleBrands.map(m => m.brand.brandName).join(', '), activeDate: new Date(a.activeDate) })));
                });
        }
        getNewsArticles();

        const getBrands = async () => {
            apiAdmin.get(`/brands`)
                .then((response) => {
                    setBrands(response.data.filter(b => b.brandId !== 4));
                });
        }
        getBrands();

    }, [submitted, deleted]);

    useEffect(() => {
        if (!newsArticleIdToEdit || newsArticleIdToEdit === -1) return;
        const getNewsArticle = async () => {
            apiAdmin.get(`/news-article?newsArticleId=${newsArticleIdToEdit}`)
                .then((response) => {
                    setFileDropzone(null);
                    setImageDropzone(null);
                    setNewsArticleToEdit(response.data);
                    setValue('title', response.data.title);
                    setValue('activeDate', DateTime.fromISO(response.data.activeDate));
                    setValue('body', response.data.body);
                    setValue('fileDesc', response.data.fileDesc);
                    response.data.newsArticleBrands.forEach((brand) => {
                        setValue(brands.find(b => b.brandId === brand.brandId).brandName.replaceAll(' ', ''), true);
                    });
                });
        }
        getNewsArticle();
    }, [newsArticleIdToEdit]);

    useEffect(() => {
        if (newsArticleToEdit || newsArticleIdToEdit === -1) {
            setSubmitted(false);
            if (imageDropzone) imageDropzone.disable();
            setImageDropzone(new Dropzone("div#image", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/news-article/image", acceptedFiles: '.webp', createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, headers: { 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
            if (fileDropzone) fileDropzone.disable();
            setFileDropzone(new Dropzone("div#file", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/news-article/file",  maxFilesize: 15000000, maxFiles: 1, headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
            if (newsArticleToEdit) {
                setEditorState(FormatHTMLForWYSIWYGEditor(newsArticleToEdit.body));
                
            } else { 
                setEditorState(EditorState.createEmpty());
            }
        }
    }, [newsArticleToEdit, newsArticleIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setNewsArticleIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add News Article
                </span>
            </GridToolbarContainer>
        );
    }
    const onSubmit = data => {
        var newsArticleBrands = [];
        brands.forEach((brand) => {
            if (data[brand.brandName.replaceAll(' ', '')]) newsArticleBrands.push({ brandId: brand.brandId })
        });
        const newsArticleObject = {
            ...data,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            photoName: imageDropzone.getAcceptedFiles().length > 0 ? imageDropzone.getAcceptedFiles()[0].name : '',
            fileName: fileDropzone.getAcceptedFiles().length > 0 ? fileDropzone.getAcceptedFiles()[0].name : '',
            newsArticleBrands,
            newsArticleId: newsArticleIdToEdit
        }
        const saveNewsArticle = async () => {
            await apiAdmin.post(`/save-news-article`, newsArticleObject)
                .then((response) => {
                    if (response.data) {
                        setNewsArticleIdToEdit(null);
                        setNewsArticleToEdit(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the news article. Please try again.")
                    }
                });
        }
        saveNewsArticle();
    }

    const deleteNewsArticle = () => {
        const deleteArticle = async () => {
            await apiAdmin.post(`/delete-news-article?newsArticleId=`+ newsArticleIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setNewsArticleIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the news article. Please try again.")
                    }
                });
        }
        deleteArticle();
    }
    
    return (
        <div>
            <Helmet>
                <title>News Articles</title>
            </Helmet>
            <h1>News Articles</h1>
            
            {newsArticleToEdit || newsArticleIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <TextField
                                label="Title *"
                                {...register("title", {required: true})}
                                className="w-100"
                            />
                            {errors?.title?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            {brands && brands.map((brand, index) => { 
                                return <div className="form-check form-switch" key={'check_'+index}>
                                    <input className="form-check-input" type="checkbox" id={brand.brandName.replaceAll(' ', '')} {...register(brand.brandName.replaceAll(' ', ''))}  />
                                    <label className="form-check-label" htmlFor={brand.brandName.replaceAll(' ', '')}>{brand.brandName}</label>
                                </div>
                            })}
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="activeDate"
                                control={control}
                                rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("activeDate")).isValid}}}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker value={value} onChange={onChange} className="w-100" label="Date *" />
                                )}
                            />
                            {errors?.activeDate?.type === "activeDateValid" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <OutlinedDiv label="Body">
                                <Editor
                                    editorState={editorState}
                                    editorClassName="wysiwyg"
                                    onEditorStateChange={(e) => setEditorState(e)}
                                    />
                            </OutlinedDiv>
                        </div>

                        <div className="mb-3">
                            <TextField
                                label="File Description"
                                {...register("fileDesc")}
                                className="w-100"
                            />
                        </div>
                                              
                        {newsArticleToEdit && newsArticleToEdit.fileName && <><div className="mb-3"> <OutlinedDiv label="Current File"><a target="_blank" rel="noreferrer" href={FilePath('/documents/newsattachments/' + newsArticleToEdit.fileName)}>{newsArticleToEdit.fileName}</a></OutlinedDiv></div></>}
                        
                        <div className="mb-3">                       
                            <OutlinedDiv label="New File">
                                <div id="file" className="dropzone dropzone-previews border-0">
                                    <div className="dz-message" data-dz-message><span>File Upload</span></div>
                                </div>
                            </OutlinedDiv>
                        </div>
                        <div className="mb-3">
                            <OutlinedDiv label="Image">
                                <div id="image" className="w-100 dropzone dropzone-previews border-0">
                                    <div className="dz-message" data-dz-message><span>Image Upload</span></div>
                                </div>
                            </OutlinedDiv>
                        </div>
                        <div className="mb-3">
                            {newsArticleToEdit && newsArticleToEdit.photoName && <img src={FilePath('/images/newsimages/' + newsArticleToEdit.photoName)} width="300" alt='News Article'/> }
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setNewsArticleIdToEdit(null); setNewsArticleToEdit(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted &&  <div className="alert alert-success alert-dismissible" role="alert">News Article saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">News Article deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)}aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={newsArticles}
                    columns={columns}
                    loading={newsArticles.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete News Article?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this news article?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteNewsArticle()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsArticles;
