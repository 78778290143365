import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import apiAdmin from '../../services/api-admin';
import { MdHistory, MdHistoryToggleOff, MdDelete } from 'react-icons/md';
import { DateTime } from 'luxon';
import SpinnerLoader from '../layout/spinner-loader';

const ClothingStoreManagement = (props) => {
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [completedOrders, setCompletedOrders] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [headerName, setHeaderName] = useState('Mark as Complete');
    const [showCompleted, setShowCompleted] = useState(false);  
    
    useEffect(() => {
        const getClothingStorePurchases = async () => {
            try {
                const response = await apiAdmin.get('/get-purchases');
                setPurchaseDetails(response.data.map((a) => ({ id: a.p.clothingStorePurchaseId, datePlaced: a.date, teamMemberName: a.teamMemberName, officeLocation: a.p.officeLocation, complete: a.p.complete })));
            } catch (err) {
                setError('Failed to fetch purchase details.');
            }
        };

        getClothingStorePurchases();
    }, [completed]);

    const toggleShowCompleted = () => {
        setShowCompleted(!showCompleted);
        setHeaderName(headerName === 'Mark as Complete' ? 'Mark as Active' : 'Mark as Complete');
    };

    const filteredPurchaseDetails = purchaseDetails.filter(order => order.complete === showCompleted);
    function HistoryDetailPanelToggle(props) {
        const { value: isExpanded } = props;

        return (
            <Tooltip title={isExpanded ? "Hide Order Details" : "View Order Details"}>
                <div>
                    {isExpanded ? <MdHistoryToggleOff className="lg-grid-button-icon" /> : <MdHistory className="lg-grid-button-icon" />}
                </div>
            </Tooltip>
        );
    }

    function CustomToolbar(props) {
        return (
            <GridToolbarContainer className="d-flex align-items-end justify-content-between">
                <GridToolbarQuickFilter />
                <button onClick={toggleShowCompleted} className="btn btn-secondary ml-2">
                    {showCompleted ? 'Show Active Orders' : 'Show Completed Orders'}
                </button>
                <button onClick={submitCompletedOrders} className="btn btn-primary ml-auto">Update Order Status</button>
            </GridToolbarContainer>
        );
    }

    const handleCheckboxClick = (id) => {
        setCompletedOrders((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const submitCompletedOrders = async () => {
        try {
            const completedOrderIds = Object.keys(completedOrders).filter(id => completedOrders[id]);
            await apiAdmin.post('/submit-completed-orders', { completedOrderIds });
            setCompleted(true);
            setCompletedOrders({});
        } catch (err) {
            setError('Failed to submit completed orders');
        }
    };

    const columns = [
        { field: 'id', headerName: 'Order #', flex: 150 },
        { field: 'datePlaced', headerName: 'Date Placed', flex: 100, type: 'date', valueGetter: (val) => { return DateTime.fromISO(val).toJSDate(); } },
        { field: 'teamMemberName', headerName: 'Team Member', flex: 150 },
        { field: 'officeLocation', headerName: 'Office Location', flex: 150 },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            flex: .2,
            align: 'center',
            renderCell: (params) => (
                <HistoryDetailPanelToggle id={params.id} value={params.value} />
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: headerName,
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<input type="checkbox" checked={!!completedOrders[id]} onChange={() => handleCheckboxClick(id)} />}
                        label="Completed"
                        className="textPrimary"
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    const deleteProduct = () => {
        const deleteItem = async () => {
            await apiAdmin.post(`/delete-order-item?productId=` + productIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setProductIdToDelete(null);
                        setDeleted(true);
                    } else {
                        setError("There was an error deleting the product. Please try again.")
                    }
                });
        }
        deleteItem();
    }
    function DetailPanelContent({ row }) {
        const [loadingOrderDetails, setLoadingOrderDetails] = useState(true);
        const [orderDetails, setOrderDetails] = useState([]);
        const [updatedDetails, setUpdatedDetails] = useState([]);

        useEffect(() => {
            const getOrderDetails = async () => {
                try {
                    const response = await apiAdmin.get(`/order-details?purchaseId=${row.id}`);
                    setOrderDetails(response.data);
                    setUpdatedDetails(response.data);
                    setLoadingOrderDetails(false);
                } catch (error) {
                    setLoadingOrderDetails(false);
                }
            };

            setLoadingOrderDetails(true);
            getOrderDetails();
        }, [row.id]);

        const handleNoteChange = (index, value) => {
            const newDetails = [...updatedDetails];
            newDetails[index] = { ...newDetails[index], notes: value };
            setUpdatedDetails(newDetails);
        };

        const handleShippedChange = (index, checked) => {
            const newDetails = [...updatedDetails];
            newDetails[index] = { ...newDetails[index], shipped: checked };
            setUpdatedDetails(newDetails);
        };

        const handleSubmit = async () => {
            await apiAdmin.post(`/update-order-details`, updatedDetails)
                .then((response) => {
                    if (response.data) {
                        setSubmitted(true);
                    } else {
                        setError("There was an error saving the product. Please try again.")
                    }
                });
        }

        return (
            <div className="container">
                {loadingOrderDetails && <SpinnerLoader />}
                {!loadingOrderDetails && orderDetails.length > 0 &&
                    <div className="row">
                        <div className="col-10">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Quantity</th>
                                        <th>Size / Color</th>
                                        <th>Logo / Logo Location</th>
                                        <th>Product #</th>
                                        <th>Description</th>
                                        <th>Notes</th>
                                        <th>Shipped?</th>
                                        <th>Deleted</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {updatedDetails.map((t, i) => (
                                        <tr key={i}>
                                            <td>{t.quantity}</td>
                                            <td>{t.size} / {t.color}</td>
                                            <td>{t.companyLogo} / {t.logoLocation}</td>
                                            <td>{t.productNumber}</td>
                                            <td>{t.description}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={t.notes || ''}
                                                    onChange={(e) => handleNoteChange(i, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={t.shipped || false}
                                                    onChange={(e) => handleShippedChange(i, e.target.checked)}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-link p-0"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal"
                                                    onClick={() => {
                                                        setProductIdToDelete(t.clothingStoreCartItemId);
                                                        setDeleted(false);
                                                    }}
                                                >
                                                    <MdDelete />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={handleSubmit} className="btn btn-primary mt-3">Submit Changes</button>
                        </div>
                    </div>
                }
            </div>
        );
    }

    const getDetailPanelContent = (params) => <DetailPanelContent row={params.row} />;

    return (
        <div>
            <Helmet>
                <title>Clothing Store Order Management</title>
            </Helmet>
            <h1>Clothing Store Order Management</h1>
            {showCompleted ? <h2>Completed Orders</h2> : <h2>Active Orders</h2>}
            <div className="w-100 h-100">
                {completed && <div className="alert alert-success alert-dismissible" role="alert">Order status updated.<button type="button" className="btn-close" onClick={() => setCompleted(false)} aria-label="Close"></button></div>}
                {submitted && <div className="alert alert-success alert-dismissible" role="alert">Order details saved!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                {deleted && <div className="alert alert-warning alert-dismissible" role="alert">Order item deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                
                <DataGridPro
                    rows={filteredPurchaseDetails}
                    columns={columns}
                    loading={!purchaseDetails || purchaseDetails.length === 0}
                   
                   
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    pagination
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                        toolbar: {
                            printOptions: { disableToolbarButton: true },
                        },
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={({ row }) => 'auto'}
                />
            </div>
            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Item?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this item?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteProduct()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClothingStoreManagement;
