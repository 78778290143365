/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, Controller} from "react-hook-form";
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { FilePath, blurOnEnterKey, } from '../../services/utilities';
import { TextField, MenuItem, FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import OutlinedDiv from '../layout/OutlinedDiv';
import { useOktaAuth } from '@okta/okta-react';

const ClothingStoreProducts = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm();
    const { authState } = useOktaAuth();
    const [products, setProducts] = useState([]);
    const [productIdToEdit, setProductIdToEdit] = useState(null);
    const [productToEdit, setProductToEdit] = useState(null);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [imageDropzone, setImageDropzone] = useState(null);
    const [thumbnailDropzone, setThumbnailDropzone] = useState(null);
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [category, setCategory] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedColors, setSelectedColors] = useState([]);
    const [logoLocation, setLogoLocation] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);

    const sizes = ["S,M,L,XL,XXL", "XS,S,M,L,XL,XXL,XXXL,XXXXL", "One Size", "N/A", "Custom"]
    const gender = ["Women's", "Men's", "Unisex"]
    const colors = ["Black", "White", "Tan", "Grey", "Custom"]

    const columns = [
        { field: 'productNumber', headerName: 'Product Number', flex: 150 },
        { field: 'brand', headerName: 'Brand', flex: 150 },
        { field: 'price', headerName: 'Price', flex: 150, type: 'number', valueFormatter: (val) => { return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); } },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setProductIdToEdit(id) }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setProductIdToDelete(id); setDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal" role="button"
                    />,
                ];
            }
        }
    ];

    const handleColorChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedColors(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        const getProducts = async () => {
            apiAdmin.get(`/get-products`)
                .then((response) => {
                    setProducts(response.data.map((a) => ({ id: a.clothingStoreProductId, productNumber: a.productNumber, brand: a.brand, price: a.price })));
                });
        }
        getProducts();

        const getProductCategories = async () => {
            apiAdmin.get(`/get-categories`)
                .then((response) => {
                    setCategory(response.data);
                });
        }
        getProductCategories();
       
        const getLogoLocations = async () => {
            apiAdmin.get(`/get-logo-locations`)
                .then((response) => {
                    setLogoLocation(response.data);
                });
        }
        getLogoLocations();

    }, [submitted, deleted]);

    useEffect(() => {
        if (!productIdToEdit || productIdToEdit === -1) return;
        const getProducts = async () => {
            apiAdmin.get(`/get-product?productId=${productIdToEdit}`)
                .then((response) => {
                    setProductToEdit(response.data);
                    setValue('productNumber', response.data.productNumber);
                    setValue('description', response.data.description);
                    setValue('price', response.data.price);
                    setSelectedCategory(response.data.clothingStoreCategoryId);
                    if (!sizes.includes(response.data.sizeOptions)) {
                        setSelectedSize("Custom");
                        setValue('customSize', response.data.sizeOptions);
                    } else {
                        setSelectedSize(response.data.sizeOptions);
                    }
                    if (!colors.includes(response.data.colorOptions)) {
                        setSelectedColors(["Custom"]);
                        setValue('customColors', response.data.colorOptions);
                    } else {
                        setSelectedColors(Array.isArray(response.data.colorOptions) ? response.data.colorOptions : [response.data.colorOptions]);
                    }
                    setSelectedGender(response.data.gender);
                    setThumbnailDropzone(null);
                    setImageDropzone(null);
                    setValue('new', response.data.new);
                    setValue('brand', response.data.brand);
                    setSelectedLocation(response.data.logoLocationId);
                    setValue('inHouseFulfillment', response.data.inHouseFulfillment);   
                });
        }
        getProducts();
    }, [productIdToEdit]);
    
    useEffect(() => {
        if (productToEdit || productIdToEdit === -1) {
            setSubmitted(false);
            if (imageDropzone) imageDropzone.disable();
            setImageDropzone(new Dropzone("div#image", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/clothing-store-products/image", acceptedFiles: '.webp, .jpg', createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, headers: { 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
            if (thumbnailDropzone) thumbnailDropzone.disable();
            setThumbnailDropzone(new Dropzone("div#thumbnail", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/clothing-store-products/image", acceptedFiles: '.webp, .jpg', createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, headers: { 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
        }
    }, [productToEdit, productIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer className="d-flex align-items-end justify-content-between">
                <span className="btn-link btn" onClick={() => { setProductIdToEdit(-1) }}>
                    <MdAdd className="mb-1" /> Add New Product
                </span>
                <GridToolbarQuickFilter {...props.quickFilterProps} className="ml-auto" />
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        const clothingProductObject = {
            ...data,
            clothingStoreCategoryId: selectedCategory,
            sizeOptions: selectedSize === 'Custom' ? data.customSize : selectedSize,
            colorOptions: selectedColors.includes('Custom')
                ? selectedColors.filter(color => color !== 'Custom').concat(data.customColors).join(', ')
                : selectedColors.join(', '),
            gender: selectedGender,
            logoLocationId: selectedLocation,
            detailImage: imageDropzone.getAcceptedFiles().length > 0 ? imageDropzone.getAcceptedFiles()[0].name : '',
            thumbnail: thumbnailDropzone.getAcceptedFiles().length > 0 ? thumbnailDropzone.getAcceptedFiles()[0].name : '',
            clothingStoreProductId: productIdToEdit
        }

        const saveProducts= async () => {
            await apiAdmin.post(`/save-product`, clothingProductObject)
                .then((response) => {
                    if (response.data) {
                        setProductIdToEdit(null);
                        setProductToEdit(null);
                        setSubmitted(true);
                        reset();
                        setSelectedLocation([]);
                        setSelectedSize(null);
                        setSelectedColors([]);
                        setSelectedCategory([]);
                        setSelectedGender(null);
                    } else {
                        setError("There was an error saving the product. Please try again.")
                    }
                });
        }
        saveProducts();
    }

    const deleteProduct = () => {
        const deleteItem = async () => {
            await apiAdmin.post(`/delete-product?productId=` + productIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setProductIdToDelete(null);
                        setDeleted(true);
                    } else {
                        setError("There was an error deleting the product. Please try again.")
                    }
                });
        }
        deleteItem();
    }

        return (
            <div>
                <Helmet>
                    <title>Clothing Store Products</title>
                </Helmet>
                <h1>Clothing Store Products</h1>

                {productToEdit || productIdToEdit === -1 ? <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="border p-3">
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <TextField
                                            label="Product Number *"
                                            {...register("productNumber", { required: true })}
                                            className="w-100"
                                        />
                                        {errors?.productNumber?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                    <div className="col-6">
                                        <Controller
                                            name="category"
                                            control={control}
                                            defaultValue={selectedCategory}
                                            rules={{ required: 'Category is required' }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Category"
                                                    value={selectedCategory}
                                                    className="w-100"
                                                    error={!!errors.category}
                                                    helperText={errors.category ? errors.category.message : ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedCategory(e.target.value);
                                                    }}
                                                >
                                                    {category.map((option) => (
                                                        <MenuItem key={option.clothingStoreCategoryId} value={option.clothingStoreCategoryId}>
                                                            {option.categoryName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <TextField
                                            label="Brand *"
                                            {...register("brand", { required: true })}
                                            className="w-100"
                                        />
                                        {errors?.brand?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            label="Price *"
                                            {...register("price", { required: true })}
                                            className="w-100"
                                        />
                                        {errors?.price?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <TextField
                                    label="Description  *"
                                    {...register("description", { required: true })}
                                    className="w-100"
                                />
                                {errors?.description?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <FormControl fullWidth error={!!errors.colors}>
                                            <InputLabel id="multiple-color-label">Color(s)</InputLabel>
                                            <Controller
                                                name="colors"
                                                control={control}
                                                defaultValue={selectedColors}
                                                rules={{ required: 'At least one color is required' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        labelId="multiple-color-label"
                                                        id="multiple-color"
                                                        multiple
                                                        value={selectedColors}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleColorChange(e);
                                                        }}
                                                        input={<OutlinedInput label="Color(s)" />}
                                                    >
                                                        {colors.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.colors && <p>{errors.colors.message}</p>}
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        <Controller
                                            name="logoLocation"
                                            control={control}
                                            defaultValue={selectedLocation}
                                            rules={{ required: 'Logo Location is required' }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Logo Location"
                                                    value={selectedLocation}
                                                    className="w-100"
                                                    error={!!errors.logoLocation}
                                                    helperText={errors.logoLocation ? errors.logoLocation.message : ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedLocation(e.target.value);
                                                    }}
                                                >
                                                    {logoLocation.map((option) => (
                                                        <MenuItem key={option.clothingStoreLogoLocationId} value={option.clothingStoreLogoLocationId}>
                                                            {option.location}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            {selectedColors && selectedColors.includes("Custom") && <div className="mb-3">
                                <TextField
                                    label="Custom Color Options"
                                    {...register("customColors")}
                                    className="w-100"
                                    helperText="Comma separated values."
                                />
                            </div>}
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <Controller
                                            name="size"
                                            control={control}
                                            defaultValue={selectedSize}
                                            rules={{ required: 'Size is required' }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Size"
                                                    value={selectedSize}
                                                    className="w-100"
                                                    error={!!errors.size}
                                                    helperText={errors.size ? errors.size.message : ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedSize(e.target.value);
                                                    }}
                                                >
                                                    {sizes.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Controller
                                            name="gender"
                                            control={control}
                                            defaultValue={selectedGender}
                                            rules={{ required: 'Gender is required' }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Gender"
                                                    value={selectedGender}
                                                    className="w-100"
                                                    error={!!errors.gender}
                                                    helperText={errors.gender ? errors.gender.message : ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedGender(e.target.value);
                                                    }}
                                                >
                                                    {gender.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>     
                                </div>
                            </div>
                            {selectedSize && selectedSize === 'Custom' && <div className="mb-3">
                                <TextField
                                    label="Custom Size Options"
                                    {...register("customSize")}
                                    className="w-100"
                                    helperText="Comma separated values."
                                />
                            </div>}
                            <div className="mb-3">
                                <div className="row">
                                    
                                    <div className="col-3">
                                        <div className="form-check form-switch col-6">
                                            <label className="form-check-label" htmlFor="new">New?</label>
                                            <input className="form-check-input" type="checkbox" id="new" {...register("new")} />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-check form-switch col-6">
                                            <label className="form-check-label" htmlFor="inHouseFulfillment">In-House</label>
                                            <input className="form-check-input" type="checkbox" id="inHouseFulfillment" {...register("inHouseFulfillment")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <OutlinedDiv label="Thumbnail">
                                    <div id="thumbnail" className="dropzone dropzone-previews border-0">
                                        <div className="dz-message" data-dz-message><span>Thumbnail Upload</span></div>
                                    </div>
                                </OutlinedDiv>
                            </div>
                            <div className="mb-3">
                                {productToEdit && productToEdit.thumbnail && <img src={FilePath('/images/clothingproducts/' + productToEdit.thumbnail)} width="300" alt='Product Thumbnail' />}
                            </div>
                            <div className="mb-3">
                                <OutlinedDiv label="Detailed Image">
                                    <div id="image" className="w-100 dropzone dropzone-previews border-0">
                                        <div className="dz-message" data-dz-message><span>Image Upload</span></div>
                                    </div>
                                </OutlinedDiv>
                            </div>
                            <div className="mb-3">
                                {productToEdit && productToEdit.detailImage && <img src={FilePath('/images/clothingproducts/' + productToEdit.detailImage)} width="300" alt='Detailed Product' />}
                            </div>
                        </div>
                        <div className="pt-2">
                            <button type="submit" className="btn btn-primary me-4">Submit</button>
                            <button type="button" className="btn btn-primary" onClick={() => { setProductIdToEdit(null); setProductToEdit(null); setSubmitted(false); setError(null); reset(); setSelectedSize(null); setSelectedGender(null); setSelectedCategory(null); setSelectedLocation(null); setSelectedColors([]); setSelectedLocation(null); }}>Cancel</button>
                        </div>
                    </form>
                    </>
                    :
                    <>
                        {submitted && <div className="alert alert-success alert-dismissible" role="alert">Product saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
                        {deleted && <div className="alert alert-warning alert-dismissible" role="alert"> Product deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                        {error && <div className="alert alert-danger" role="alert">{error}</div>}
                        <DataGridPro
                            rows={products}
                            columns={columns}
                            loading={products.length === 0}
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'skeleton',
                                    noRowsVariant: 'skeleton',
                                },
                                toolbar: {
                                    quickFilterProps: {
                                        onKeyDown: blurOnEnterKey
                                    }
                                }
                            }}
                            autoHeight
                            rowHeight={38}
                            disableRowSelectionOnClick
                            alignItems="center"
                            slots={{
                                toolbar: EditToolbar,
                              
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                },
                            }}
                            pagination
                        />
                    </>
                }

                <div className="modal shown" tabIndex="-1" id="deleteModal" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Product?</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this product?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteProduct()}>Yes, Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    
}

export default ClothingStoreProducts;
