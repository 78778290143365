/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import apiAdmin from '../../services/api-admin';
import { TextField, MenuItem } from '@mui/material';
import PromoItemRow from './promo-item-row';
import { FilePath, FormatMoney } from '../../services/utilities';
import PreviousPromoOrders from './previous-promo-orders';
import { MdDownload } from 'react-icons/md';
import fileDownload from 'js-file-download';
import SpinnerLoader from '../layout/spinner-loader';
import { useParams } from 'react-router-dom';

const PromoOrderForm = (props) => {
    const [promoItems, setPromoItems] = useState([]);
    const [promoOrderZones, setPromoOrderZones] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [selectedZone, setSelectedZone] = useState(1);
    const [error, setError] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [offices, setOffices] = useState([]);
    const [notes, setNotes] = useState('');
    const [cart, setCart] = useState([]);
    const [previewItem, setPreviewItem] = useState(null);
    const [settings, setSettings] = useState(null);
    let { paramPromoZone } = useParams();

    useEffect(() => {
        if (paramPromoZone && promoOrderZones && parseInt(paramPromoZone)) setSelectedZone(parseInt(paramPromoZone));

    }, [paramPromoZone, promoOrderZones]);

    useEffect(() => {
        if (offices.length === 0 || teamMembers.length === 0) return;
        const getPromoItems = async () => {
            apiAdmin.get(`/get-promo-orderform-items?promoOrderZoneId=` + selectedZone)
                .then((response) => {
                    setPromoItems(response.data.filter(p => !p.discontinued));

                });
        }

        getPromoItems();

    }, [teamMembers, offices, selectedZone]);

    useEffect(() => {
        const getTeamMembers = async () => {
            apiAdmin.get(`/team-members`)
                .then((response) => {
                    setTeamMembers(response.data.filter(b => b.brandId !== 4));
                });
        }
        getTeamMembers();

        const getSettings = async () => {
            apiAdmin.get(`/promo-order-settings`)
                .then((response) => {
                    setSettings(response.data);
                });
        }
        getSettings();

        const getPromoOrderZones = async () => {
            apiAdmin.get(`/promo-order-zones`)
                .then((response) => {
                    setPromoOrderZones(response.data);
                });
        }
        getPromoOrderZones();

        const getOffices = async () => {
            apiAdmin.get(`/offices`)
                .then((response) => {
                    setOffices(response.data);
                });
        }
        getOffices();
    }, []);

    const addToCart = (item, quantity) => { 
        var newCart = [...cart];

        if (newCart.map(m => m.promoItem.promoItemId).includes(item.promoItemId)) {
            var foundItem = newCart.find(m => m.promoItem.promoItemId === item.promoItemId);
            foundItem.quantity = quantity;
            if (quantity === '0' || quantity === '') newCart = newCart.filter(c => c.promoItem.promoItemId !== item.promoItemId)
        } else { 
            newCart.push({promoItem: item, quantity: quantity})
        }
        setCart(newCart);
    }

    
    const submitOrder = () => { 
        const promoOrderZone = promoOrderZones.find(p => p.promoOrderZoneId === selectedZone);
        const promoOrderObject = {
            promoOrderZoneId: selectedZone, 
            notes: notes, 
            orderContactTeamMemberId: promoOrderZone.contactTeamMemberId,
            promoOrderItems: cart.map(c => ({ promoItemId: c.promoItem.promoItemId, quantity: c.quantity }))
        }

        const placePromoOrder = async () => {
            await apiAdmin.post(`/place-promo-order`, promoOrderObject)
                .then((response) => {
                    if (response.data) {
                        setCart([]);
                        setNotes('');
                        setSubmitted(true);
                    } else { 
                        setError("There was an error placing the promo order. Please try again.")
                    }

                });
        }        
        placePromoOrder();
    }

    
    useEffect(() => {
        if (submitted) { 
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [submitted]);

    const downloadCatalog = () => { 
        const promoOrderZone = promoOrderZones.find(p => p.promoOrderZoneId === selectedZone);
        var html = `<!DOCTYPE html><html><head runat="server"><title></title><style>.itemthumbnail-printable {
                  max-width: 75%;
                  max-height: 200px;
                }
                .iteminfo-printable {
                  line-height: 1.1em;
                }
                .text-center {
                    text-align: center;
                }
                html * {
                  font-family: 'Roboto Condensed' !important;
                  border-collapse: collapse;
                }
                .border {
                    border: 1px solid lightgray;
                    padding: 10px;
                }
                tr    { 
					page-break-inside:avoid; 
					page-break-after:auto;
				}
                .container {
                    width: 100%;
                }
            </style></head><body><div class="container">
            <h3 class="text-center">Promotional Items Available</h3>
            <h5 class="text-center">${promoOrderZone.zoneName} - ${new Date().toLocaleDateString("en-US")}</h5>
            <table style="width:100%"><tbody>`;
            
        promoItems.forEach((promoItem, index) => { 
            if (index % 3 === 0) html += `<tr>`;
            html += `<td class="text-center p-3 border">
                        <img src="${FilePath('/promoitemimages/' + promoItem.photo)}" class="itemthumbnail-printable rounded" alt="${promoItem.itemName}">
                        <div class="iteminfo-printable mt-1">
                            ${promoItem.itemName} <strong ${promoItem.new ? '' : 'style="display: none"'}>New!</strong> <br/>
                            <small class="mt-1">${FormatMoney(promoItem.price * settings.shippingAndHandlingRate)} each, 
                            min. order: ${promoItem.minimumOrderQuantity}</small>
                        </div>
                    </td>`;
            if (index % 3 === 2) html += `</tr/>`;

        })
        html += '</tbody></table></div></body></html>';
        const download = async () => {
            await apiAdmin.post(`/promo-order-catalog`, html, { responseType: 'blob' })
                .then((response) => {
                    if (response.data) {
                        fileDownload(response.data, 'PromoItemsCatalog.pdf');

                    } else { 
                        setError("There was an error downloading the catalog. Please try again.")
                    }
                });
        }
        download();
    }

    const renderZone = () => { 
        const promoOrderZone = promoOrderZones.find(p => p.promoOrderZoneId === selectedZone);
        if (!promoOrderZone) return <></>;

        var teamMember = teamMembers.find(tm => tm.teamMemberId === promoOrderZone.contactTeamMemberId);
        if (!teamMember) return <></>;
        var office = offices.find(o => o.officeId === promoOrderZone.deliveryOfficeId);
        if (!office) return <></>;

        return <>
            <strong>{promoOrderZone.zoneName}</strong><br/>
            {teamMember.firstName} {teamMember.lastName}<br/>
            {office.address1} <br/>
            {office.city}, {office.stateAbbrev} {office.zip}
        </>
    }
   
   
    return (
        <div>
            <Helmet>
                <title>Promo Order</title>
            </Helmet>
            <h1>Place an Order For Promotional Items</h1>
            {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Promo Order placed successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <div className={paramPromoZone && "d-none"}>
                <TextField
                    select
                    label="Promo Order Zone"
                    value={selectedZone}
                    className="w-100"
                    onChange={(e) => setSelectedZone(e.target.value)}
                >
                    {promoOrderZones.map((option) => (
                        <MenuItem key={"r_" + option.promoOrderZoneId} value={option.promoOrderZoneId}>
                            {option.zoneName}
                        </MenuItem>
                    ))}
                </TextField>
                <hr />
            </div>
            <div className="row">
                <div className="col">
                    {renderZone()}
                </div>
                <div className="col">
                    {selectedZone && <button className="btn btn-link float-end" onClick={() => downloadCatalog()}><MdDownload className="me-2" /> Download Printable Catalog</button>}
                </div>
            </div>
            <hr/>
            {promoItems && promoItems.length > 0 ? <>
            <div className="row">
                <div className="col-2 d-none d-md-flex">
                    <strong>Quantity</strong>
                </div>
                <div className="col-2 d-flex d-md-none">
                    <strong>#</strong>
                </div>
                <div className="col-4"><strong>Item Name</strong></div>
                <div className="col-2"><strong>Image</strong></div>
                <div className="col-2 d-none d-md-flex">
                    <strong>Price With S&H</strong>
                </div>
                <div className="col-2 d-flex d-md-none">
                    <strong>Price</strong>
                </div>
                <div className="col-2"><strong>Total</strong></div>
            </div>
            {promoItems && settings && promoItems.map((pi) => {
                return <PromoItemRow promoItem={pi} key={"row_" + pi.promoItemId} addToCart={addToCart} setPreviewItem={setPreviewItem} settings={settings} cart={cart} />
            })}
            <div className="row border-top">
                <div className="col-2 float-end">
                    <strong>{cart && cart.map(c => parseInt(c.quantity)).reduce((a, b) => a + b, 0)}</strong>
                </div>
                <div className="col-4"><strong>Total Items</strong></div>
                <div className="col-2 d-none d-md-flex">
                    <strong>Total Cost For This Order:</strong>
                </div>
                <div className="col-4 d-flex d-md-none">
                    <strong>Total Cost:</strong>
                </div>                
                <div className="col-2 offset-md-2"><strong>{FormatMoney(cart && cart.map(c => ((Math.round(c.promoItem.price * settings.shippingAndHandlingRate * 100) / 100) * c.quantity)).reduce((a, b) => a + b, 0))}</strong></div>
            </div>
            <div className="row">
                <div className="col-7 offset-5 col-md-4 offset-md-8">
                    <TextField  className="w-100" multiline rows="3" label="What items do you need first?" onChange={(e) => setNotes(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-6 offset-6 col-md-4 offset-md-8 py-2 ">
                    <button type="submit" disabled={cart.length === 0} className="btn btn-primary me-4" onClick={() => submitOrder()}>Submit</button>
                    <button type="button" className="btn btn-primary" onClick={() => { setSubmitted(false); setError(null); setCart([])} }>Clear</button>
                </div>
            </div>
            </> : <SpinnerLoader/>}
            <PreviousPromoOrders promoOrderZoneId={selectedZone} settings={settings} promoItems={promoItems} />

            <div className="modal shown" tabIndex="-1" id="previewModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title">{previewItem && previewItem.itemName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=> setPreviewItem(null)}></button>
                        </div>
                        <div className="modal-body">
                            {previewItem && <img src={FilePath('/promoitemimages/' + previewItem.photo)} alt={previewItem.itemName} height="auto" width="100%" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromoOrderForm;
