import React, { useEffect, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { AdminUserContext } from '../contexts/user-context';
import SpinnerLoader from "../components/layout/spinner-loader";

const RequireAuth = ({ children }) => {
    const { oktaAuth, authState } = useOktaAuth();
    const { adminUserProfile } = useContext(AdminUserContext);

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
    }, [oktaAuth, !!authState, authState?.isAuthenticated]);



    if (!authState || !authState?.isAuthenticated || !adminUserProfile) {
        return (<SpinnerLoader />);
    }

    return (children);
};

export default RequireAuth