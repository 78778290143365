/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import './preferred-customers.scss';

const PreferredCustomerSiteUsage = ({ preferredCustomerId, setPreferredCustomerIdToEdit }) => {
    const [preferredCustomerSiteUses, setPreferredCustomerSiteUses] = useState([]);
    const [loading, setLoading] = useState(true);
    const paymentColumns = [
        { field: 'date', headerName: 'Date', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ') } },
        { field: 'feature', headerName: 'Page', flex: 200 },
        { field: 'misc', headerName: 'Misc', flex: 400 },
    ];

    useEffect(() => {
        const getPreferredCustomerSiteUses = async () => {
            apiAdmin.get(`/preferred-customer-site-uses?preferredCustomerId=${preferredCustomerId}`)
                .then((response) => {
                    setPreferredCustomerSiteUses(response.data.map(p => ({
                        id: p.activityLogId,
                        date: new Date(p.date),
                        feature: p.feature,
                        misc: p.misc,
                    })));
                    setLoading(false);
                });
        }
        getPreferredCustomerSiteUses();
    }, [preferredCustomerId]);

    return (
        <div>
            <DataGridPro
                rows={preferredCustomerSiteUses}
                columns={paymentColumns}
                loading={loading}
                slotProps={{
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    }
                }}
                autoHeight
                rowHeight={38}
                disableRowSelectionOnClick
                alignItems="center"
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                    }}
                pagination
                />
            <input type="button" className="btn btn-primary mt-3" value="Back To List" onClick={() => setPreferredCustomerIdToEdit(null)} />
        </div>
    );
}

export default PreferredCustomerSiteUsage;
