import React from "react";

import { TextField} from '@mui/material';

const InputComponent = React.forwardRef((props, ref) => (
    <div {...props} {...ref} />
))
const OutlinedDiv = ({ children, label }) => {
  return (
    <TextField
          variant="outlined"
          label={label}
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
              inputComponent: InputComponent
          }}
          inputProps={{ children: children }}
          className="w-100"
    />
  );
};
export default OutlinedDiv;
