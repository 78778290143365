/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { Paper } from '@mui/material';
import { FormatMoney } from '../../services/utilities';

const PreviousPromoOrders = ({promoOrderZoneId, promoItems, settings}) => {
    const [promoOrders, setPromoOrders] = useState([]);
    const columns = [
        { field: 'orderDate', headerName: 'Order Date', flex: 150, renderCell: function (t) { return t.value.toLocaleDateString("en-US")} },
        { field: 'totalItemsOrdered', headerName: 'Total Items Ordered', flex: 200 },
        { field: 'totalCost', headerName: 'Total Cost With S&H', flex: 200 },
    ];

    useEffect(() => {
        if (!settings || !promoItems || !promoOrderZoneId) return;
        const getPromoOrders = async () => {
            apiAdmin.get(`/promo-orders?promoOrderZoneId=` + promoOrderZoneId)
                .then((response) => {
                    setPromoOrders(response.data.map((a) => {
                        var totalItems = 0;
                        var totalCost = 0;
                        a.promoOrderItems.forEach((item) => { 
                            totalItems += item.quantity;
                            totalCost += ((Math.round(item.promoItem.price * settings.shippingAndHandlingRate * 100) / 100) * item.quantity)
                        })
                        return ({ id: a.promoOrderId, 
                            orderDate: new Date(a.orderDate), 
                            totalItemsOrdered: totalItems, 
                            totalCost: FormatMoney(totalCost), 
                            promoOrderItems: a.promoOrderItems})
                    }));

                });
        }

        getPromoOrders();

    }, [settings, promoItems, promoOrderZoneId]);

    function DetailPanelContent({ row }) {
        return (
            <Paper className="p-3">
                <ul>
                    {promoItems && row.promoOrderItems.map((i, index) => { 
                        return <li key={"p_" + row.id + "_" + index}>{i.promoItem.itemName} - {i.quantity}</li>}
                    )
                    }
                </ul>
            </Paper>
        )    
    }
    
    return (
        <DataGridPro
            rows={promoOrders}
            columns={columns}
            loading={promoOrders.length === 0}
            slotProps={{
                loadingOverlay: {
                    variant: 'skeleton',
                    noRowsVariant: 'skeleton',
                },
            }}
            autoHeight
            rowHeight={38}
            disableRowSelectionOnClick
            alignItems="center"
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                },
            }}
            pagination
            getDetailPanelContent={DetailPanelContent}
            getDetailPanelHeight={() => 'auto'}
        />
    );
}

export default PreviousPromoOrders;
