import {
    MdHouse,
    MdSchool,
    MdOutlineAccountBalance,
    MdOutlineChromeReaderMode,
    MdOutlineShoppingBag,
    MdOutlineWarningAmber,
    MdOutlineShoppingCart,
    MdOutlineQrCode2,
    MdOutlineGolfCourse,
    MdOutlineBusinessCenter,
    MdOutlinePaid,
    MdOutlineGroups,
    MdOutlineArticle,
    MdOutlineBallot,
    MdOutlineEmail,
    MdOutlineAccountCircle,
    MdOutlineTableView,
    MdOutlineArtTrack,
    MdOutlineLocalOffer,
    MdOutlinePersonPinCircle,
    MdOutlineWorkspacePremium,
    MdOutlineHomeWork,
    MdOutlineTableChart,
    MdOutlinePushPin
} from 'react-icons/md';

const NavIcon = ({ route }) => {

    return (
        <>
            {route === "/" && <MdHouse />}
            {route === "/classes" && <MdSchool />}
            {route === "/clothing-store-products" && <MdOutlineShoppingBag />}
            {route === "/clothing-store-management" && <MdOutlineBallot />}
            {route === "/bucksone-balances" && <MdOutlinePaid />}
            {route === "/site-messages" && <MdOutlineWarningAmber />}
            {route === "/golf-tournaments" && <MdOutlineGolfCourse />}
            {route === "/sales-toolkit" && <MdOutlineBusinessCenter />}
            {route === "/item-of-the-week" && <MdOutlineChromeReaderMode />}
            {route === "/foreclosure-sales" && <MdOutlineAccountBalance />}
            {route === "/digital-home-portfolios" && <MdOutlineQrCode2 />}
            {route === "/preferred-customers" && <MdOutlineGroups />}
            {route === "/home-page-cards" && <MdOutlineArticle />}
            {route === "/reps-of-the-year" && <MdOutlineWorkspacePremium />}
            {route === "/ecards" && <MdOutlineEmail />}
            {route === "/team-members" && <MdOutlineAccountCircle />}
            {route === "/market-share" && <MdOutlineTableView />}
            {route === "/news-articles" && <MdOutlineArtTrack />}
            {route === "/promo-order-form" && <MdOutlineShoppingCart />}
            {route === "/promo-item-management" && <MdOutlineLocalOffer />}
            {route === "/promo-order-management" && <MdOutlineTableChart />}
            {route === "/promo-order-zones" && <MdOutlinePersonPinCircle />}
            {route === "/offices" && <MdOutlineHomeWork />}
            {route === "/job-postings" && <MdOutlinePushPin />}
        </>
    );
}
export default NavIcon