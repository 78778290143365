import React from 'react';
import { useLocation } from 'react-router-dom';
import NavMenu from './nav/nav-menu';
import NavMobileMenu from './nav/nav-mobile-menu';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from '../error-boundary';
import { Suspense } from 'react';
import SpinnerLoader from './spinner-loader';
import { Outlet } from 'react-router-dom';
import './layout.scss';

const Layout = () => {
    const location = useLocation();

    return (
        <HelmetProvider>
            <a href="#skip-link-target" className="visually-hidden-focusable">Skip to main content</a>
            <div className="row h-100 w-100 gx-0">
                <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                    <NavMenu />
                </div>
                <div className="d-md-none mobile-menu">
                    <NavMobileMenu />
                </div>

                <div className="col-md-8 col-lg-9 col-xl-10 col-12 mobile-content">
                    <div role="complementary">
                    </div>
                    <div role="main" className="page-content-container container-fluid container-lg pb-3 pt-3 pt-lg-0" id="skip-link-target">
                        <ErrorBoundary key={location?.pathname}>
                            <Suspense fallback={<div><SpinnerLoader /></div>}>
                                <Outlet />
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                </div>

            </div>
        </HelmetProvider>
    );
}

export default Layout;