import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FilePath } from '../services/utilities';

export const OopsContent = () => {
    return <>
        <h1>Oops! Something went wrong</h1>

        <p className="fw-bold">We're sorry! Something went wrong.  We've been notified and are researching the issue.</p>

        <p>In the mean time, try one of these pages:</p>

        <ul>
            <li><Link to="/">Home page</Link></li>
            <li><Link to="/preferred-customer/sign-up">Sign up to become a Preferred Customer</Link></li>
            <li><Link to="/tools">Tools</Link></li>
            <li><Link to="/team">Meet the Team</Link></li>
            <li><Link to="/locations">Contact and Locations</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/careers">Careers</Link></li>
        </ul>
        <img src={FilePath("/images/subaru-genericbrand.png")} alt="T1 Subaru" className="w-75 pt-1 pt-lg-5" />
    </>;
};

const Oops = (props) => {

    return (
        <div>
            <Helmet>
                <title>Oops!</title>
            </Helmet>
            <OopsContent />
        </div>
    );
}

export default Oops;
