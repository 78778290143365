import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import apiAdmin from '../../../services/api-admin';
import { MdEdit, MdDelete } from 'react-icons/md';
import EcardEditor from './ecard-editor';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import './ecards.scss';

const Ecards = (props) => {
    const [savedTemplates, setSavedTemplates] = useState(null);
    const [designs, setDesigns] = useState(null);
    const [cardTemplateToUse, setCardTemplateToUse] = useState(null);
    const [cardDesignSelected, setCardDesignSelected] = useState(null);
    const [saveTemplateResponse, setSaveTemplateResponse] = useState(null);
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);

    useEffect(() => {
        const getSavedTemplates = async () => {
            apiAdmin.get(`/ecard/saved-templates`)
                .then((response) => {
                    setSavedTemplates(response.data);
                });
        }

        const getDesigns = async () => {
            apiAdmin.get(`/ecard/designs`)
                .then((response) => {
                    setDesigns(response.data);
                });
        }

        const getBrands = async () => {
            apiAdmin.get(`/brands`)
                .then((response) => {
                    setBrands(response.data.filter(b => b.brandId !== 4));
                    setSelectedBrand(response.data[0]); // todo... set to brand matching user email
                });
        }


        getBrands();
        getSavedTemplates();
        getDesigns();
    }, []);

    useEffect(() => {
        if (!saveTemplateResponse) return;
        var updatedSavedTemplates = savedTemplates.map(s => {
            if (s.template.templateId === saveTemplateResponse.template.templateId) {
                return saveTemplateResponse;
            }
            else {
                return s;
            }
        });
        if (!some(savedTemplates, st => st.template.templateId === saveTemplateResponse.template.templateId)) {
            updatedSavedTemplates.push(saveTemplateResponse);
        }
        setSavedTemplates(updatedSavedTemplates);
        setSaveTemplateResponse(null);
        setCardTemplateToUse(saveTemplateResponse.template);
    }, [saveTemplateResponse]);

    const startOver = () => {
        setCardDesignSelected(null);
        setCardTemplateToUse(null);
    };

    const deleteCardTemplate = (t) => {
        const doDeleteTemplate = async (templateId) => {
            apiAdmin.post(`/ecard/delete-template`, templateId )
                .then((response) => {
                    setSavedTemplates(savedTemplates.filter(s => s.template.templateId !== templateId));
                });
        }
        doDeleteTemplate(t.template.templateId);
    }
    
    return (
        <div className="ecards">
            <Helmet>
                <title>ECards</title>
            </Helmet>
            <h1>ECards</h1>
            <div>
                {selectedBrand && 
                    <div className="dropdown text-end">
                        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedBrand.brandName}
                        </button>
                        <ul className="dropdown-menu">
                            {brands && brands.map((brand, index) => {
                                return <li key={"brand_" + brand.brandId}><span className="dropdown-item btn-link" onClick={() => setSelectedBrand(brand)} >{brand.brandName}</span></li>
                            })}
                        </ul>
                    </div>
                }

                {!cardTemplateToUse && !cardDesignSelected &&
                    <>
                        {savedTemplates && savedTemplates.length > 0 && <div>
                            <h2>Select from your saved templates:</h2>
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                {orderBy(savedTemplates, [t => t.template.modifiedOn || t.template.createdOn], ['desc']).map((t, idx) => {
                                    return <div key={idx} className="col mb-3">
                                        <div className="px-2 py-2 card border-0">
                                            <h5 className="mb-1">{t.displayName}</h5>
                                            <div className="small mb-1 fst-italic">Last modified {DateTime.fromISO(t.template.modifiedOn || t.template.createdOn).toFormat('MM-dd-yyyy')}</div>
                                            <div className="d-flex">
                                                <div className="d-flex flex-column">
                                                    <button type="button" className="btn btn-link" onClick={() => { setCardTemplateToUse(t.template); setCardDesignSelected(t.design) }}><MdEdit title="Start With This Template" className="lg-react-icons" /></button>
                                                    <button type="button" className="btn btn-link" onClick={() => deleteCardTemplate(t)}><MdDelete title="Delete Template" className="md-react-icons" /></button>
                                                </div>
                                                <div className="iframe-wrap text-center">
                                                    <iframe className="card-selection" title={t.templateName} srcDoc={t.htmlPreview}></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        }

                        {designs && <div>
                            <h2>{(savedTemplates && savedTemplates.length > 0) ? <>Or c</> : <>C</>}reate a new Ecard:</h2>
                            <p>Select a design:</p>
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                {designs.filter(d => d.design.brandId === selectedBrand.brandId).map((d, idx) => { 
                                    return <div key={idx} className="col mb-3">
                                        <div className="px-2 py-2 card border-0">
                                            <h5>{d.displayName}</h5>
                                            <div className="d-flex">
                                                <div className="d-flex flex-column">
                                                    <button type="button" className="btn btn-link" onClick={() => setCardDesignSelected(d.design)}><MdEdit title="Start With This Design" className="md-react-icons" /></button>
                                                </div>
                                                <div className="iframe-wrap">
                                                    <iframe title={d.title} srcDoc={d.htmlPreview}></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        }
                    </>}
                {(cardTemplateToUse || cardDesignSelected) &&
                    <EcardEditor cardDesign={cardDesignSelected} cardTemplateToUse={cardTemplateToUse} setSaveTemplateResponse={setSaveTemplateResponse} startOver={startOver} />
                }
            </div>
        </div>
    );
}

export default Ecards;
