/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import apiAdmin from '../../services/api-admin';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm} from "react-hook-form";
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { TextField, MenuItem } from '@mui/material';
import OutlinedDiv from '../layout/OutlinedDiv';
import SalesToolkitEditCategories from './sales-toolkit-edit-categories';
import { useOktaAuth } from '@okta/okta-react';

const SalesToolkit = () => {
    const { register, handleSubmit, setValue, reset, formState: { errors }  } = useForm();
    const { authState } = useOktaAuth();
    const [salesToolkitDocumentIdToEdit, setSalesToolkitDocumentIdToEdit] = useState(null);
    const [salesToolkitDocumentToEdit, setSalesToolkitDocumentToEdit] = useState(null);
    const [salesToolkitDocumentIdToDelete, setSalesToolkitDocumentIdToDelete] = useState(null);
    const [fileDropzone, setFileDropzone] = useState(null);
    const [salesToolkitCategories, setSalesToolkitCategories] = useState([]);
    const [salesToolkitDocuments, setSalesToolkitDocuments] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [categorySubmitted, setCategorySubmitted] = useState(false);
    const [categoryDeleted, setCategoryDeleted] = useState(false);
    const [editingCategories, setEditingCategories] = useState(false);

    const getSalesToolkitDocuments = async () => {
        apiAdmin.get(`/sales-toolkit-documents`)
            .then((response) => {
                setSalesToolkitDocuments(response.data.map((a) => ({ id: a.documentId, categoryId: a.categoryId, title: { title: a.title, url: a.url }, internalOnly: a.internalOnly ? "Yes" : "No", canDownloadAsImage: a.canDownloadAsImage ? "Yes" : "No" })));
            });
    }
    useEffect(() => {

        const getSalesToolkitCategories = async () => {
            apiAdmin.get(`/sales-toolkit-categories`)
                .then((response) => {
                    setSalesToolkitCategories(response.data);
                });
        }

        getSalesToolkitCategories();


        getSalesToolkitDocuments();
    }, [deleted, submitted, categoryDeleted, categorySubmitted]);

    const columns = [
        { field: 'title', headerName: 'Title', flex: 400, renderCell: function (t) { return <a href={t.value.url} target="_blank" rel="noreferrer">{t.value.title}</a>}},
        { field: 'internalOnly', headerName: 'Internal', flex: 100 },
        { field: 'canDownloadAsImage', headerName: 'Image?', flex: 100 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setSalesToolkitDocumentIdToEdit(id) }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setSalesToolkitDocumentIdToDelete(id); setDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target={"#deleteModal"} role="button" 
                    />,
                ];
            }
        }
        ];

    useEffect(() => {
        if (!salesToolkitDocumentIdToEdit || salesToolkitDocumentIdToEdit === -1) return;
        const getSalesToolkitDocument = async () => {
            apiAdmin.get(`/sales-toolkit-document?salesToolkitDocumentId=${salesToolkitDocumentIdToEdit}`)
                .then((response) => {
                    setFileDropzone(null);
                    setSalesToolkitDocumentToEdit(response.data);
                    setValue('title', response.data.title);
                    setValue('internalOnly', response.data.internalOnly);
                    setValue('canDownloadAsImage', response.data.canDownloadAsImage);
                    setSelectedCategoryId(response.data.categoryId);
                    
                });
        }
        getSalesToolkitDocument();
    }, [salesToolkitDocumentIdToEdit]);

    useEffect(() => {
        if (salesToolkitDocumentToEdit || salesToolkitDocumentIdToEdit === -1) {
            setSubmitted(false);
            if (fileDropzone) fileDropzone.destroy();
            setFileDropzone(new Dropzone("div#file", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/sales-toolkit/upload",  maxFilesize: 15000000, maxFiles: 1, headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
        }
    }, [salesToolkitDocumentToEdit, salesToolkitDocumentIdToEdit]);


    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setSalesToolkitDocumentIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Document
                </span>
                <span className="btn-link btn" onClick={() => {setEditingCategories(true)} }>
                    <MdEdit className="mb-1"/> Edit Categories
                </span>                
                {salesToolkitCategories && selectedCategoryId && <div className="col">
                    <div className="dropdown float-end">
                        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {salesToolkitCategories.find(s => s.categoryId === selectedCategoryId) && salesToolkitCategories.find(s => s.categoryId === selectedCategoryId).category}
                        </button>
                        <ul className="dropdown-menu">
                            {salesToolkitCategories && salesToolkitCategories.map((category, index) => {
                                return <li key={"category_" + category.categoryId}><span className="dropdown-item btn-link" onClick={() => setSelectedCategoryId(category.categoryId)}>{category.category}</span></li>
                            })}
                        </ul>
                    </div>
                </div>}
            </GridToolbarContainer>
        );
    }
    const onSubmit = data => {

        const salesToolkitDocumentObject = {
            ...data,
            url: fileDropzone.getAcceptedFiles().length > 0 ? fileDropzone.getAcceptedFiles()[0].name : '',
            documentId: salesToolkitDocumentIdToEdit,
            categoryId: selectedCategoryId
        }
        const saveSalesToolkitDocument = async () => {
            await apiAdmin.post(`/save-sales-toolkit-document`, salesToolkitDocumentObject)
                .then((response) => {
                    if (response.data) {
                        setSalesToolkitDocumentIdToEdit(null);
                        setSalesToolkitDocumentToEdit(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the document. Please try again.")
                    }
                });
        }
        saveSalesToolkitDocument();
    }

    const deleteSalesToolkitDocument = () => {
        const deleteArticle = async () => {
            await apiAdmin.post(`/delete-sales-toolkit-document?salesToolkitDocumentId=`+ salesToolkitDocumentIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setSalesToolkitDocumentIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the document. Please try again.")
                    }
                });
        }
        deleteArticle();
    }

    const resortRows = (e) => { 
        var obj = {
            oldIndex: e.oldIndex + 1,
            targetIndex: e.targetIndex + 1,
            salesToolkitDocumentId: e.row.id,
            categoryId: e.row.categoryId
        }
        const resortDocuments = async () => {
            await apiAdmin.post(`save-sales-toolkit-sort`, obj)
                .then((response) => {
                    if (response.statusText !== "OK") {
                        setError("There was an error re-sorting the documents. Please try again.")
                    } else { 
                        getSalesToolkitDocuments();
                    }
                });
        }
        resortDocuments();
    }
    
    return (
        <div>   
            <Helmet>
                <title>Sales Toolkit</title>
            </Helmet>
            <h1>Sales Toolkit</h1>
            {categorySubmitted &&  <div className="alert alert-success alert-dismissible" role="alert">Category saved successfully!<button type="button" className="btn-close" onClick={() => setCategorySubmitted(false)} aria-label="Close"></button></div>}
            {categoryDeleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Category deleted successfully!<button type="button" className="btn-close" onClick={() => setCategoryDeleted(false)}aria-label="Close"></button></div>}
            {submitted &&  <div className="alert alert-success alert-dismissible" role="alert">Document saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
            {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Document deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)}aria-label="Close"></button></div>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            {editingCategories && <SalesToolkitEditCategories categories={salesToolkitCategories.map((a) => ({ id: a.categoryId, icon: a.icon, category: a.category, sort: a.sort }))} setCategoryDeleted={setCategoryDeleted} setCategorySubmitted={setCategorySubmitted} setCategoryError={setError} setEditingCategories={setEditingCategories} />}
            {!editingCategories && <>
                {salesToolkitDocumentToEdit || salesToolkitDocumentIdToEdit === -1 ? <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="border p-3">
                            <div className="mb-3">
                                <TextField
                                    label="Title *"
                                    {...register("title", {required: true})}
                                    className="w-100"
                                />
                                {errors?.title?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                            <div className="mb-3">
                            <TextField
                                select
                                label="Category"
                                value={selectedCategoryId}
                                className="w-100"
                                onChange={(e) => setSelectedCategoryId(e.target.value)}
                            >
                                    {salesToolkitCategories.map((category) => (
                                        <MenuItem key={"r_" + category.categoryId} value={category.categoryId}>
                                            {category.category}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="internalOnly" {...register("internalOnly")}  />
                                    <label className="form-check-label" htmlFor="internalOnly">Internal Only?</label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="canDownloadAsImage" {...register("canDownloadAsImage")}  />
                                    <label className="form-check-label" htmlFor="canDownloadAsImage">Can Download As Image?</label>
                                </div>
                            </div>
                            {salesToolkitDocumentToEdit && salesToolkitDocumentToEdit.fileName && <><div className="mb-3"> <OutlinedDiv label="Current File"><a target="_blank" rel="noreferrer" href={salesToolkitDocumentToEdit.url}>{salesToolkitDocumentToEdit.url}</a></OutlinedDiv></div></>}
                            <div className="mb-3">                       
                                <OutlinedDiv label="New File">
                                    <div id="file" className="dropzone dropzone-previews border-0">
                                        <div className="dz-message" data-dz-message><span>File Upload</span></div>
                                    </div>
                                </OutlinedDiv>
                            </div>

                        </div>
                        <div className="pt-2">
                            <button type="submit" className="btn btn-primary me-4">Submit</button>
                            <button type="button" className="btn btn-primary" onClick={() => { setSalesToolkitDocumentIdToEdit(null); setSalesToolkitDocumentToEdit(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                        </div>
                    </form>
                </>
                :
                <>
                        <DataGridPro
                            rows={salesToolkitDocuments.filter(f => f.categoryId === selectedCategoryId)}
                            columns={columns}
                            loading={salesToolkitDocuments.length === 0}
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'skeleton',
                                    noRowsVariant: 'skeleton',
                                },
                            }}
                            autoHeight
                            rowHeight={38}
                            disableRowSelectionOnClick
                            alignItems="center"
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            rowReordering
                            onRowOrderChange={(e) => resortRows(e)}
                        />
                </>
                }
            </>
        }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Document?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this document?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteSalesToolkitDocument()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalesToolkit;
