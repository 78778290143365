import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const LoggedOut = () => {

    return (
        <>
            <Helmet>
                <title>TitleOne Admin</title>
            </Helmet>
            <p>You've been logged out.</p>

            <p><Link to="/">Log Back In</Link></p>
        </>
  );
}

export default LoggedOut;