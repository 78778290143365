/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import apiAdmin from '../../services/api-admin';
import { useForm, Controller } from "react-hook-form";
import { FormatPhoneInput } from '../../services/utilities';
import { TextField, MenuItem } from '@mui/material';
import './preferred-customers.scss';
import SpinnerLoader from '../layout/spinner-loader';

const PreferredCustomerAccountInfo = ({preferredCustomerIdToEdit, setPreferredCustomerIdToEdit, setError, setSubmitted, regions, statuses}) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }, control } = useForm({defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      title: '',
      companyName: '',
      mailingAddress: '',
      mailingCity: '',
      mailingState: '',
      mailingZip: '',
      mobilePhone: '',
      email: '',
      referredBy: ''

    }});
    const [selectedStatus, setSelectedStatus] = useState(1);
    const [mobilePhoneInputValue, setMobilePhoneInputValue] = useState("");
    const [selectedProfession, setSelectedProfession] = useState("Real Estate");
    const [selectedPrimaryRegion, setSelectedPrimaryRegion] = useState(1);
    const [resetNote, setResetNote] = useState('');
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(true);

    const professions = ['Real Estate', 'Lending/Mortgage', 'Building', 'Development', 'Commercial', 'Appraisal/Property Management', 'Attorney', 'Other']

    const handlePhoneInput = (e) => {
        const formattedPhoneNumber = FormatPhoneInput(e.target.value);
        setMobilePhoneInputValue(formattedPhoneNumber);
    };

    useEffect(() => {
        if (!preferredCustomerIdToEdit) return;
        setLoading(true);
        const getPreferredCustomer = async () => {
            apiAdmin.get(`/preferred-customer?preferredCustomerId=${preferredCustomerIdToEdit}`)
                .then((response) => {
                    Object.keys(response.data).forEach(function (key, index) {
                        setValue(key, response.data[key]);
                    });   
                    setMobilePhoneInputValue(response.data.mobilePhone)
                    setSelectedPrimaryRegion(response.data.primaryRegionId)
                    setSelectedStatus(response.data.preferredCustomerStatusId);
                    setSelectedProfession(response.data.profession);
                    setLoading(false);
                });
        }
        getPreferredCustomer();
    }, [preferredCustomerIdToEdit]);

    const sendAccountInfo = () => {
        const send = async () => {
            await apiAdmin.post(`/send-account-info?preferredCustomerId=` + preferredCustomerIdToEdit, resetNote)
                .then((response) => {
                    if (response.data) {
                        setSent(true);
                        setResetNote('');
                    } else { 
                        setError("There was an error sending the preferred customer account information. Please try again.")
                    }
                });
        }
        send();
    }

    const onSubmit = data => {
        const preferredCustomersObject = {
            ...data,
            preferredCustomerId: preferredCustomerIdToEdit,
            primaryRegionId: selectedPrimaryRegion,
            profession: selectedProfession,
            preferredCustomerStatusId: selectedStatus,
            mobilePhone: mobilePhoneInputValue
        }
        const savePreferredCustomer = async () => {
            await apiAdmin.post(`/save-preferred-customer`, preferredCustomersObject)
                .then((response) => {
                    if (response.data) {
                        setPreferredCustomerIdToEdit(null);
                        setSubmitted(true);
                        reset();
                        setSelectedStatus(1);
                        setSelectedPrimaryRegion(1);
                        setSelectedProfession('Real Estate');
                    } else { 
                        setError("There was an error saving the preferred customer. Please try again.")
                    }
                });
        }
        savePreferredCustomer();
    }
    return (
            <>
                {sent && <><div className="alert alert-success alert-dismissible" role="alert">Preferred Customer account information sent successfully!<button type="button" className="btn-close" onClick={() => setSent(false)} aria-label="Close"></button></div></>}
            {loading ? <SpinnerLoader /> : <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <TextField
                        select
                        label="Status"
                        value={selectedStatus}
                        className="w-100"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        {statuses.map((s) => (
                            <MenuItem key={"status_" + s.preferredCustomerStatusId} value={s.preferredCustomerStatusId}>
                                {s.statusDescription}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="mb-3">
                    <div className="row">
                        <div className="col">
                            <Controller
                                name="username"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Username *"
                                        className="w-100"
                                    />
                                )} />
                            {errors?.username?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#resetModal">Send Username and <strong>Password Reset</strong> Link</button>
                        </div>
                    </div>

                </div>
                <div className="mb-3">
                    <div className="row">
                        <div className="col">
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="First Name *"
                                        className="w-100"
                                    />
                                )} />
                            {errors?.firstName?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="col">
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Name *"
                                        className="w-100"
                                    />
                                )} />
                            {errors?.lastName?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="employee">Team Member</label>
                        <input className="form-check-input" type="checkbox" id="employee" {...register("employee")} />
                    </div>
                </div>
                <div className="mb-3">
                    <TextField
                        select
                        label="Primary Region"
                        value={selectedPrimaryRegion}
                        className="w-100"
                        onChange={(e) => setSelectedPrimaryRegion(e.target.value)}
                    >
                        {regions.map((option) => (
                            <MenuItem key={"r_" + option.regionId} value={option.regionId}>
                                {option.regionName}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="mb-3">
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Title"
                                className="w-100"
                            />
                        )} />
                </div>
                <div className="mb-3">
                    <TextField
                        select
                        label="Profession"
                        value={selectedProfession}
                        className="w-100"
                        onChange={(e) => setSelectedProfession(e.target.value)}
                    >
                        {professions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="mb-3">
                    <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Business Name"
                                className="w-100"
                            />
                        )} />
                </div>
                <div className="mb-3">
                    <div className="row">
                        <div className="col">
                            <Controller
                                name="mailingAddress"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mailing Address"
                                        className="w-100"
                                    />
                                )} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-5">
                            <Controller
                                name="mailingCity"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="City"
                                        className="w-100"
                                    />
                                )} />
                        </div>
                        <div className="col-3">
                            <Controller
                                name="mailingState"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="State"
                                        className="w-100"
                                        inputProps={{ maxLength: 2 }}
                                    />
                                )} />
                        </div>
                        <div className="col-4">
                            <Controller
                                name="mailingZip"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Zip"
                                        className="w-100"
                                        inputProps={{ maxLength: 5 }}
                                    />
                                )} />
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <TextField
                        label="Mobile Phone"
                        className="w-100"
                        onChange={(e) => handlePhoneInput(e)}
                        value={mobilePhoneInputValue}
                    />
                </div>
                <div className="mb-3">
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email *"
                                className="w-100"
                            />
                        )} />
                    {errors?.email?.type === "required" && <small className="text-danger">This field is required</small>}
                </div>
                <div className="mb-3">
                    <Controller
                        name="referredBy"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Referred By"
                                className="w-100"
                                disabled
                            />
                        )} />
                </div>
                <div className="mb-3">
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="optOut">Opted out from eNews?</label>
                        <input className="form-check-input" type="checkbox" id="optOut" {...register("optOut")} />
                    </div>
                </div>
                <div className="mb-3">
                    <TextField
                        label="Change Notes"
                        {...register("changeNotes")}
                        className="w-100"
                        multiline
                        rows={3}
                    />
                </div>
                <div className="pt-2">
                    <button type="submit" className="btn btn-primary me-4">Submit</button>
                    <button type="button" className="btn btn-primary" onClick={() => { setPreferredCustomerIdToEdit(null); setSubmitted(false); setError(null); reset(); setSelectedStatus(1); setSelectedPrimaryRegion(1); setSelectedProfession('Real Estate'); }}>Cancel</button>
                </div>
            </form>}           
             
            <div className="modal shown" tabIndex="-1" id="resetModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Preferred Customer Account Information</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Sends an email with the username and a password reset link (including any yet unsaved changes, be sure to save any change made).</p>
                            <TextField label="Notes" value={resetNote} onChange={(e) => setResetNote(e.target.value)} className="w-100"></TextField>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setResetNote('')}>Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => sendAccountInfo()}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PreferredCustomerAccountInfo;
