import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { App } from './app';

const rootElement = ReactDOM.createRoot(document.getElementById('root'));


rootElement.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
);
