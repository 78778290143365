import React from 'react';
import apiAdmin from '../services/api-admin';
import { OopsContent } from './oops';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const postError = async (errObj) => {
            await apiAdmin.post(`/log-error`, errObj, { noErrorHandling: true });
        }

        if (process.env.NODE_ENV === 'development') {
            console.log("client error, will send email and redirect to 'oops' when deployed", error);
            throw error;
        }
        else {
            postError({ title: "Caught In Error Boundary", detailObject: errorInfo, detail: error.toString(), url: window.location.href, brand: this.context.selectedBrand });
        }
    }

    render() {
        if (this.state.hasError && process.env.NODE_ENV !== 'development') {
          return <OopsContent />;
        }

        return this.props.children;
    }
}
