/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd, MdDownload } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import {  FilePath, FormatPhoneInput, blurOnEnterKey } from '../../services/utilities';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { TextField, MenuItem, Select, ListSubheader, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';
import fileDownload from 'js-file-download';
import { useOktaAuth } from '@okta/okta-react';
import useWindowDimensions from '../../services/useWindowDimensions';

const TeamMembers = (props) => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control  } = useForm();
    const { authState } = useOktaAuth();
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMemberIdToEdit, setTeamMemberIdToEdit] = useState(null);
    const [teamMemberToEdit, setTeamMemberToEdit] = useState(null);
    const [teamMemberIdToDelete, setTeamMemberIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [phoneListDownloading, setPhoneListDownloading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [imageDropzone, setImageDropzone] = useState(null);
    const [regions, setRegions] = useState([]);
    const [primaryOffice, setPrimaryOffice] = useState(null);
    const [selectedOffices, setSelectedOffices] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedEOs, setSelectedEOs] = useState([]);
    const [offices, setOffices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [phoneInputValue, setPhoneInputValue] = useState("");    
    const { loadedSizeIsMobile, width } = useWindowDimensions();
    
    const handleOfficeChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOffices(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDepartmentChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedDepartments(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleEOChange = (event) => {
        const {
            target: { value },
        } = event;

        setSelectedEOs(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handlePhoneInput = (e) => {
        const formattedPhoneNumber = FormatPhoneInput(e.target.value);
        setPhoneInputValue(formattedPhoneNumber);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const columns = [
        { field: 'firstName', headerName: loadedSizeIsMobile ? 'First' : 'First Name', flex: 200, hideSortIcons: loadedSizeIsMobile },
        { field: 'lastName', headerName: loadedSizeIsMobile ? 'Last' : 'Last Name', flex: 200, hideSortIcons: loadedSizeIsMobile },

        { field: 'department', headerName: loadedSizeIsMobile ? 'Dept' : 'Department', flex: 200, hideSortIcons: loadedSizeIsMobile },
        { field: 'offices', headerName: 'Office(s)', flex: 200, hideSortIcons: loadedSizeIsMobile },
        {
            field: 'actions',
            type: 'actions',
            renderHeader: function (t) { return loadedSizeIsMobile ? <MdEdit/> : <span>Actions</span >},
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setTeamMemberIdToEdit(id) }}
                        color="inherit"
                        showInMenu={loadedSizeIsMobile}
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setTeamMemberIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button"
                        showInMenu={loadedSizeIsMobile}
                    />,
                ];
            }
        }
    ];

    const teamColumns = [
        { field: 'firstName', headerName: 'First Name', flex: 200 },
        { field: 'lastName', headerName: 'Last Name', flex: 200 },
    ];


    useEffect(() => {
        var promises = [];
        const getTeamMembers = apiAdmin.get(`/team-members`);
        promises.push(getTeamMembers);

        const getRegionsWithOffices = apiAdmin.get(`/regions-with-offices`);
        promises.push(getRegionsWithOffices);

        const getOffices = apiAdmin.get(`/offices`);
        promises.push(getOffices);

        const getDepartments = apiAdmin.get(`/teammember-departments`);
        promises.push(getDepartments);

        Promise.all(promises)
            .then((responses) => {
                setTeamMembers(responses[0].data.map((a) => ({
                    id: a.teamMemberId, firstName: a.firstName, lastName: a.lastName, title: a.title,
                    department: a.departments.map(d => d.departmentName).join(', '),
                    offices: a.offices.map(o => o.officeName).join(', ')
                })));
                setRegions(responses[1].data);
                setOffices(responses[2].data);                    
                setDepartments(responses[3].data);

            });



    }, [submitted, deleted]);

    const downloadPhoneList = () => {
        const download = async () => {
            apiAdmin.get(`/team-phone-list`, { responseType: 'blob' })
                .then((response) => {
                    fileDownload(response.data, 'PhoneList.pdf');
                    setPhoneListDownloading(false);
                });
        }
        setPhoneListDownloading(true);
        download();
    }



    useEffect(() => {
        if (!teamMemberIdToEdit || teamMemberIdToEdit === -1) return;
        const getTeamMember = async () => {
            apiAdmin.get(`/team-member?teamMemberId=${teamMemberIdToEdit}`)
                .then((response) => {
                    setImageDropzone(null);
                    setTeamMemberToEdit(response.data);
                    setValue('firstName', response.data.firstName);
                    setValue('lastName', response.data.lastName);
                    setValue('nameTitle', response.data.nameTitle);
                    setValue('title', response.data.title);
                    setValue('email', response.data.email);
                    setPhoneInputValue(response.data.directLine);
                    setValue('text', response.data.text);
                    setValue('useHeadshotInSignature', response.data.useHeadshotInSignature);
                    setValue('anniversaryDate', DateTime.fromISO(response.data.anniversaryDate));
                    setValue('birthday', DateTime.fromISO(response.data.birthday));
                    setValue('listed', response.data.listed);
                    setPrimaryOffice(response.data.primaryOfficeId)
                    setSelectedDepartments(departments.filter(d => response.data.departments.map(td => td.departmentId).includes(d.departmentId)).map(d => d.departmentName));
                    setSelectedEOs(response.data.escrowOfficers.map(eo => eo.escrowOfficerId));
                    setSelectedOffices(offices.filter(t => response.data.offices.map(t => t.officeId).includes(t.officeId)).map(f => f.officeName));
                });
        }
        getTeamMember();
    }, [teamMemberIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer className="row">
                <div className="col">
                    <span className="btn-link btn px-0 px-md-2" onClick={() => {setTeamMemberIdToEdit(-1)} }>
                        <MdAdd className="mb-1"/> Add Team Member
                    </span>
                </div>
                <div className="col">
                    <GridToolbarQuickFilter  {...props.quickFilterProps} className="float-end ms-3"/>
                    <div className="btn btn-link pt-0 float-end" role="button" disabled={phoneListDownloading} onClick={downloadPhoneList}>{phoneListDownloading ? <><span className="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status">Downloading...</span></> : <><MdDownload title="Download Pdf" className="lg-react-icons pe-2" />Phone List</>}</div>

                </div>
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        var teamMemberoffices = offices.filter(o => selectedOffices.includes(o.officeName));
        var teamMemberDepartments = departments.filter(d => selectedDepartments.includes(d.departmentName));
        const teamMemberObject = {
            ...data,
            teamMemberId: teamMemberIdToEdit,
            image: imageDropzone.getAcceptedFiles().length > 0 ? imageDropzone.getAcceptedFiles()[0].name : '',
            offices: teamMemberoffices, 
            escrowOfficerIds: selectedEOs,
            departments: teamMemberDepartments,
            primaryOfficeId: teamMemberoffices.length === 1 || !primaryOffice ? teamMemberoffices[0].officeId : primaryOffice
        }
        const saveTeamMember = async () => {
            await apiAdmin.post(`/save-team-member`, teamMemberObject)
                .then((response) => {
                    if (response.data) {
                        setTeamMemberIdToEdit(null);
                        setTeamMemberToEdit(null);
                        setSubmitted(true);
                        reset();
                        setSelectedDepartments([]);
                        setSelectedOffices([]);
                        setSelectedEOs([])
                        setPrimaryOffice(null);
                        setPhoneInputValue('')
                    } else { 
                        setError("There was an error saving the team member. Please try again.")
                    }
                });
        }
        saveTeamMember();
    }

    const deleteTeamMember = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-team-member?teamMemberId=`+ teamMemberIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setTeamMemberIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the team member. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    
    useEffect(() => {
        if (teamMemberToEdit || teamMemberIdToEdit === -1) {
            setSubmitted(false);
            if (imageDropzone) imageDropzone.disable();
            setImageDropzone(new Dropzone("div#image", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/team-member/image", createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, renameFile: renameFile,  headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
        }
    }, [teamMemberToEdit, teamMemberIdToEdit]);


    const renameFile = file => {
        if (!teamMemberToEdit) return file.name;
        return (teamMemberToEdit.firstName + '-' + teamMemberToEdit.lastName + '.png').replaceAll(' ', '-').toLowerCase();
    };

    const renderSelectGroup = region => {
        const items = region.offices.map(o => {
            return (
                <MenuItem key={'office_' + o.officeId} value={o.officeName}>
                    {o.officeName}
                </MenuItem>
            );
        });
        return [<ListSubheader>{region.regionName}</ListSubheader>, items];
    };

    return (
        <div>
            <Helmet>
                <title>Team Members</title>
            </Helmet>
            <h1>Team Members</h1>

            {teamMemberToEdit || teamMemberIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" defaultValue={true} id="listed" {...register("listed")} />
                                <label className="form-check-label" htmlFor="listed">Listed?</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                      label="First Name *"
                                      {...register("firstName", {required: true})}
                                      className="w-100"
                                    />
                                    {errors?.firstName?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col">
                                    <TextField
                                      label="Last Name *"
                                      {...register("lastName", {required: true})}
                                      className="w-100"
                                    />
                                    {errors?.lastName?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Name Title (i.e. LPO, CPA)"
                              {...register("nameTitle")}
                              className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Job Title"
                              {...register("title")}
                              className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Email *"
                              {...register("email", {required: true})}
                              className="w-100"
                            />
                            {errors?.email?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Direct Line"
                              {...register("directLine")}
                              className="w-100"
                              onChange={(e) => handlePhoneInput(e)}
                              value={phoneInputValue}
                            />
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <FormControl fullWidth>
                                        <InputLabel id="multiple-department-label">Department(s)</InputLabel>
                                        <Select
                                            labelId="multiple-department-label"
                                            id="multiple-department"
                                            multiple
                                            value={selectedDepartments}
                                            onChange={handleDepartmentChange}
                                            input={<OutlinedInput label="Department(s)" />}
                                            MenuProps={MenuProps}
                                        >
                                            {departments.map(d => {
                                                return <MenuItem key={'department_' + d.departmentId} value={d.departmentName}>
                                                    {d.departmentName}
                                                </MenuItem>

                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        {(selectedDepartments.filter(d => departments.find(dl => dl.departmentName === 'Escrow'))) && <div className="mb-3">
                            <FormControl fullWidth>
                                <InputLabel id="multiple-ea-label">Escrow Officer (if EA)</InputLabel>
                                <Select
                                    labelId="multiple-ea-label"
                                    id="multiple-ea"
                                    multiple
                                    value={selectedEOs}
                                    onChange={handleEOChange}
                                    input={<OutlinedInput label="Escrow Officer (if EA)" />}
                                    MenuProps={MenuProps}
                                >
                                    {teamMembers.filter(r => r.title.includes('Escrow Officer')).map((t) => {
                                        return <MenuItem key={'eo_' + t.id} value={t.id}>
                                            {t.firstName} {t.lastName}
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>}
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-6">
                                      <FormControl fullWidth>
                                        <InputLabel id="multiple-office-label">Office(s)</InputLabel>
                                        <Select
                                          labelId="multiple-office-label"
                                          id="multiple-office"
                                          multiple
                                          value={selectedOffices}
                                          onChange={handleOfficeChange}
                                          input={<OutlinedInput label="Office(s)" />}
                                          MenuProps={MenuProps}
                                        >
                                            {regions.filter(r => r.offices && r.offices.length > 0).map((region) => renderSelectGroup(region))}
                                        </Select>
                                      </FormControl>
                                </div>
                            </div>
                        </div>
                        {selectedOffices && selectedOffices.length > 1 && <div className="mb-3">
                        <div className="row">
                            <div className="col">
                                <TextField
                                    select
                                    label="Primary Office"
                                    value={primaryOffice}
                                    className="w-100"
                                    onChange={(e) => setPrimaryOffice(e.target.value)}
                                >
                                    {offices.filter(o => selectedOffices.includes(o.officeName)).map((option) => (
                                        <MenuItem key={"po_" + option.officeId} value={option.officeId}>
                                            {option.officeName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                </div>
                            </div>
                        </div>}
                        <div className="mb-3">                            
                            <TextField
                                id="outlined-multiline-static"
                                label="Bio"
                                multiline
                                rows={4}
                                {...register("text")}
                                className="w-100"
                                helperText="Insert ** where you would like bullet points. Insert [yearsSince(2000)] where you would like the number of years auto-calculated."
                            />
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="useHeadshotInSignature" {...register("useHeadshotInSignature")} />
                                <label className="form-check-label" htmlFor="useHeadshotInSignature">Use Headshot Photo in Signature?</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    {teamMemberToEdit && teamMemberToEdit.image && <img src={FilePath('/teamimages/' + teamMemberToEdit.image.replace('.jpg', '.png').toLowerCase()) + '?i=' + DateTime.now().second} className="m-auto d-block w-50" alt='Team Member'/> }
                                </div>
                                <div className="col">
                                    <OutlinedDiv label="Image">
                                        <div id="image" className="w-100 dropzone dropzone-previews border-0">
                                            <div className="dz-message" data-dz-message><span>Upload new headshot</span></div>
                                        </div>
                                    </OutlinedDiv>
                                </div>
                            </div>        
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <Controller
                                        name="anniversaryDate"
                                        control={control}
                                        rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("anniversaryDate")).isValid}}}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker value={value} onChange={onChange} className="w-100" label="Anniversary Date"/>
                                        )}
                                    />
                                    {errors?.anniversaryDate?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col">
                                    <Controller
                                        name="birthday"
                                        control={control}
                                        rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("birthday")).isValid}}}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker value={value} onChange={onChange} className="w-100" views={['month', 'day']} label="Birthday" />
                                        )}
                                    />
                                    {errors?.birthday?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setTeamMemberIdToEdit(null); setTeamMemberToEdit(null); setSubmitted(false); setError(null); reset(); setSelectedDepartments([]); setSelectedOffices([]); setSelectedEOs([]); setPrimaryOffice(null); setPhoneInputValue('') } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Team Member saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Team Member deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={teamMembers}
                    columns={columns}
                    loading={teamMembers.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                        toolbar: {
                            quickFilterProps: {
                                onKeyDown: blurOnEnterKey
                            }
                        }
                    }}
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Team Member?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this team member?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteTeamMember()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    );
}

export default TeamMembers;
