import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import SpinnerLoader from './layout/spinner-loader';
import { TextField, Autocomplete, createFilterOptions } from '@mui/material';
import { AdminUserContext } from '../contexts/user-context';
import apiAdmin from '../services/api-admin';

const Impersonate = (props) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [impersonationComplete, setImpersonationComplete] = useState(false);
    const [options, setOptions] = useState([]);
    const filter = createFilterOptions();
    const { setAdminUserProfile, isImpersonated, setIsImpersonated } = useContext(AdminUserContext);

    useEffect(() => {
        const getUsers = async () => {
            apiAdmin.get(`/users`)
                .then((response) => {
                    var filteredAndSorted = response.data
                        .toSorted((a, b) => { return a.firstName > b.firstName ? 1 : -1; });
                    setOptions(filteredAndSorted);
                    if (isImpersonated) {
                        setValue(filteredAndSorted.filter(u => u.email === localStorage.getItem("T1AdminImpersonate")));
                    }
                    setLoadingOptions(false);
                });
        };
        getUsers();

    }, []);

    const handleImpersonate = () => {
        setAdminUserProfile(value);
        localStorage.setItem("T1AdminImpersonate", value.email);
        setImpersonationComplete(true);
    }

    useEffect(() => {
        setIsImpersonated(localStorage.getItem("T1AdminImpersonate") !== null);
    }, [localStorage.getItem("T1AdminImpersonate")]);


    return (
        <div>
            <Helmet>
                <title>Impersonate</title>
            </Helmet>
            <div>
                {loadingOptions && <SpinnerLoader />}
                {!loadingOptions && <>
                    <p>Select a user to impersonate.</p>

                    <Autocomplete
                        value={value}
                        isOptionEqualToValue={(option, value) => { return option.email === value.email }}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : `${option.firstName} ${option.lastName} - ${option.email} (${option.description})`
                        }
                        options={options}
                        autoComplete
                        includeInputInList
                        filterOptions={(o, p) => {
                            const filtered = filter(o, p);
                            return filtered;
                        }}
                        noOptionsText="No users found."
                        onChange={(event, newValue) => {
                            setOptions(newValue ? [newValue, ...options] : options);
                            setValue(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Search users" fullWidth />
                        )}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <li key={key} {...optionProps}>
                                    {option.firstName} {option.lastName} - {option.email} ({option.description})
                                </li>
                            );
                        }}
                    />
                    <div className="py-3">
                        <input type="button" className="btn btn-primary" onClick={handleImpersonate} value="Go" />
                    </div>
                    {isImpersonated && <div>
                        {impersonationComplete && <div className="alert alert-success alert-dismissible pt-3" role="alert">Impersonation successful!<button type="button" className="btn-close" onClick={() => setImpersonationComplete(false)} aria-label="Close"></button></div>}
                        <div className="pt-3">Impersonation will last until page is refreshed or you select to stop.</div>
                    </div>}
          </>}
            </div>
        </div>
    );
}

export default Impersonate;
